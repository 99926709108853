import React from 'react';
import { Navigation, Pagination, Parallax } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import Lottie from "lottie-react";
import SlideRightLottie from "../../../animation/Slide-Lottie2.json";
import { Link } from "react-router-dom";

function Story() {
  return (
    <section className="section-padding">
      <div className="container-fluid rest mb-100">
        <div className="col-md-12">
          <div className="text-center mb-30">
            <h1>Exploration Canvas</h1>
            <p>Take an idea or concept as far as posible for 10-15mins.</p>
          </div>
        </div>
        <Swiper
          modules={[Navigation, Pagination, Parallax]}
          //navigation
          //pagination={{ clickable: true }}
          parallax
          spaceBetween={0}
          centeredSlides={true}
          loop={true}
          className="project2-swiper"
          //style={{ overflow: "visible", maxWidth: "640px" }}
        >
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/explora/01.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/explora/02.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/explora/03.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/explora/04.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/explora/05.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/explora/06.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/explora/07.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/explora/08.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
        </Swiper>
        <Lottie
          id="slide-lottie-img2"
          animationData={SlideRightLottie}
          loop={true}
          style={{ marginTop: "-25px" }}
        />
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="cont section-padding">
              <div className="row">
                <div className="col-md-3 sm-mb30">
                  <h6>Stylescapes</h6>
                </div>
                <div className="col-lg-6 col-md-9">
                  <div className="text">
                    <p>
                      Stylescapes are visual tools that convey a brand's
                      aesthetic direction by combining images, colors, and
                      typography to align stakeholders on a cohesive design
                      vision. They help ensure consistency in the brand's visual
                      identity.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/brand-rsg/stylescapes/01.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/brand-rsg/stylescapes/11.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/brand-rsg/stylescapes/02.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/brand-rsg/stylescapes/12.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/brand-rsg/stylescapes/03.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/brand-rsg/stylescapes/13.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/brand-rsg/stylescapes/04.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/brand-rsg/stylescapes/14.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/brand-rsg/stylescapes/05.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/brand-rsg/stylescapes/15.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/brand-rsg/stylescapes/06.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/brand-rsg/stylescapes/16.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/brand-rsg/stylescapes/07.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/brand-rsg/stylescapes/17.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/brand-rsg/stylescapes/08.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/brand-rsg/stylescapes/18.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/brand-rsg/stylescapes/09.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/brand-rsg/stylescapes/19.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/brand-rsg/stylescapes/10.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/brand-rsg/stylescapes/20.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="cont section-padding">
              <div className="row">
                <div className="col-md-3 sm-mb30">
                  <h6>The Narrative</h6>
                </div>
                <div className="col-lg-6 col-md-9">
                  <div className="text">
                    <p>
                      3 Squares symbolizes the foundational vision of its
                      founders and their commitment to cutting-edge, inclusive
                      technology. The logo’s design elements reflect the brand’s
                      values of integrity, trust, and innovation, embodying the
                      archetype of a “Magician” as an Inventor, Transformer, and
                      Visionary. Through its harmonious blend of colors and
                      shapes, 3 Squares stands for a future-forward,
                      trustworthy, and creative brand.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="img"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/00.png"
                alt=""
                style={{ maxWidth: "900px" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid rest mb-100">
        <div className="col-md-12">
          <div className="text-center mt-120 mb-30">
            <h1>The Brand Guide</h1>
            <p>
              Snippets from the brand guide.*Sensitive info has been excluded.
            </p>
          </div>
        </div>
        <Swiper
          modules={[Navigation, Pagination, Parallax]}
          //navigation
          //pagination={{ clickable: true }}
          pagination={false}
          parallax
          spaceBetween={0}
          centeredSlides={true}
          loop={true}
          className="project2-swiper"
          style={{ overflow: "visible", maxWidth: "1920px" }}
        >
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/brandguide/01.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/brandguide/02.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/brandguide/03.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/brandguide/04.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/brandguide/05.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/brandguide/06.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/brandguide/07.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/brandguide/08.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/brandguide/09.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/brandguide/10.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/brandguide/11.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/brandguide/12.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/brandguide/13.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/brandguide/14.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/brandguide/15.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/brandguide/16.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/brandguide/17.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/brandguide/18.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/brandguide/19.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/brandguide/21.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/brandguide/20.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/brandguide/22.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/brandguide/23.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/brandguide/24.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/brandguide/20.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
        </Swiper>
        <Lottie
          id="slide-lottie-img2"
          animationData={SlideRightLottie}
          loop={true}
          style={{ marginTop: "-25px" }}
        />
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="cont section-padding">
              <div className="row mb-30">
                <div className="col-md-3 sm-mb30">
                  <h6>Outcome</h6>
                </div>

                <div className="video-frame black-bg">
                  <video
                    id="video-rsglogo"
                    className="bg-img tab-img"
                    src="/dark/assets/imgs/works/projects/brand/brand-rsg/RSG-Logo-SocialIntro.webm"
                    autoPlay
                    loop
                    muted
                    width="100%"
                    style={{ maxWidth: "1460px", maxHeight: "1290px", mixBlendMode: "screen" }}
                  />
                </div>

                <div className="col-lg-6 col-md-9">
                  <div className="text">
                    <p>
                      Successful delivery of a comprehensive brand strategy and
                      identity that positions Real Serious Games as a leader in
                      the VR gaming industry. The brand guide provides a clear
                      roadmap for consistent brand communication and design
                      across all touchpoints.
                      <br />
                      <br />
                      The Custom Mircrosoft Templates created alongside the
                      Brand Guides Document, enables the markeing team to create
                      professional and consistent documents, presentations, and
                      emails without the need for a designer.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mt-60">
                <div className="col-md-3 sm-mb30 ">
                  <h6>Next on the list</h6>
                </div>
                <div className="col-lg-6 col-md-9">
                  <div className="text">
                    <p>
                      Design and develop a responsive website for Real Serious
                      Games.
                    </p>
                    <Link
                      to="/archives/websites/rsg"
                      className="fz-28 fw-400 stroke next-archive-link"
                    >
                      RealSeriousGames.com
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Story;
