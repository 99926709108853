"use client";
import { Helmet } from "react-helmet";
import ProgressScroll from "../components/default/ProgressScroll";
import Team from "../components/default/Team";
import About from "../components/default/About";
import Skills from "../components/default/Skills";
import SkillsOther from "../components/default/SkillsOther";
import Skillhats from "../components/default/Skillhats";
import AwardsTestimonials from "../components/default/AwardsTestimonials";
import SkillsTech from "../components/default/SkillsTech";
import Footer from "../components/default/Footer";
import Lottie from "lottie-react";
import HZLogoAnimation from "../components/animation/HZ-Logo-Lottie.json";
import AboutHeader from "../components/default/AboutHeader";
import LoadingScreen from "../common/loader";

export default function AboutHz() {
  return (
    <>
      <Helmet>
        <title>Archives | About</title>
        <link rel="icon" href="/dark/imgs/nav/favicon.ico" />
        <link rel="shortcut icon" href="/dark/imgs/nav/favicon.ico" />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/plugins.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/style.css"
        />
      </Helmet>
      <body>
        <LoadingScreen />
        <ProgressScroll />
        <div id="smooth-wrapper">
          <div id="smooth-content">
            <main className="main-bg">
              <AboutHeader />
              <Team />
              <Skills />
              <SkillsOther />
              <Skillhats />
              <SkillsTech />
              <div style={{ height: "100px", width: "auto" }} />
              <AwardsTestimonials />
              <About />
            </main>
            <Footer />
            <div className="hz-lottie-container">
              <Lottie
                id="hz-logo-animation"
                animationData={HZLogoAnimation}
                loop={false}
              />
            </div>
          </div>
        </div>
      </body>
    </>
  );
}
console.clear();