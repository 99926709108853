import React from "react";
import { Link } from "react-router-dom";

function Story() {
  return (
    <section className="section-padding bord-thin-top">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="cont">
              <div className="row">
                <div className="col-md-3 sm-mb30">
                  <h6>Next on the list</h6>
                </div>
                <div className="col-lg-6 col-md-9">
                  <div className="text">
                    <p>description</p>
                    <Link
                      to="/archives/websites/rsg"
                      className="fz-28 fw-400 stroke next-archive-link"
                    >
                      Project Name
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Story;
