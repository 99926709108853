"use client";
import React, { useLayoutEffect } from "react";
import { gsap } from "gsap";
import { Navigation, Pagination, Parallax } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import Lottie from "lottie-react";
import SlideRightLottie from "../../../animation/Slide-Lottie2.json";

function Header() {
  useLayoutEffect(() => {
    const tl = gsap.timeline();
    tl.fromTo(".header-project2", { y: 200 }, { y: 0 }, "+=0.5");
    tl.fromTo(
      ".header-project2 .container-xl",
      { opacity: 0, translateY: 40 },
      { opacity: 1, translateY: 0 },
      "-=0"
    );

    // Cleanup function
    return () => tl.kill();
  }, []);

  return (
    <section
      className="header header-project2 section-padding pb-0"
      id="topofpage"
    >
      <div className="container-xl mt-80 mb-80">
        <div className="row align-items-end">
          <div className="col-lg-6">
            <div className="full-width mb-30">
              <h6 className="mb-0 main-color">Web App Design & Development</h6>
              <h2 className="mb-10">Dashten MissionCTRL</h2>
              <p>
                Design and develop operation management tool suited for remote
                office culture. With the growing options of management
                applications available in todays market to suit remote working
                culture, it's easy to get lost with navigating across various
                company platform. This app was created to pull all applications
                that are essential to day to day operations, in one place. From
                managing login and passwords, to checking marketing analytics.
              </p>
              <br />
              <p>Self-innitiative project to optimise operations.</p>
              <h6 className="mt-30 main-color">Live Url:</h6>
              <a
                href="https://realseriousgames.com/dashten"
                target="_blank"
                rel="noreferrer"
                style={{ letterSpacing: "1px" }}
              >
                realseriousgames.com/dashten
              </a>
            </div>
          </div>
          <div className="col-lg-5 offset-lg-1">
            <div className="info">
              <div className="row">
                <div className="col-md-6">
                  <div className="item mb-30">
                    <span className="opacity-8 mb-5 fz-12">Category :</span>
                    <h6>Website Design & Development | UI & UX</h6>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="item mb-30">
                    <span className="opacity-8 mb-5 fz-12">Client :</span>
                    <h6>Real Serious Games</h6>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="item mb-30">
                    <span className="opacity-8 mb-5 fz-12">Start Date :</span>
                    <h6>2022</h6>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="item">
                    <span className="opacity-8 mb-5 fz-12">Company:</span>
                    <h6>
                      Real Serious Games <br />
                      <p className=" fz-12 fw-500">
                        - Senior Designer & Marketing Lead
                      </p>
                    </h6>
                  </div>
                </div>
                <div className="col-md-12 mt-30">
                  <div className="item">
                    <span className="mb-5">Deliverables:</span>
                    <p>
                      Website Design, Website Development, SEO, Analytics,
                      Performance Optimization, User Experience, User Interface,
                      Content Strategy, Content Management, Social Media
                      Integration, API Integration, CRM Integration, CMS
                      Integration, Blog Integration, Newsletter Integration,
                      Chat Integration, Forum Integration, Support Integration,
                      Hosting Integration, Domain Integration, SSL Integration,
                      Security Integration.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid rest mb-30">
        <div
          className="img img-long-container"
          style={{
            display: "flex",
            justifyContent: "center",
            background: "#e8ebee",
            margin: "auto",
            width: "100%",
            overflowX: "scroll",

            "@media (max-width: 1400px)": {
              minWidth: "calc(100% + 100px) !important",
              justifyContent: "flex-start !important",
              overflowX: "scroll",
            },
            "@media (max-width: 768px)": {
              minWidth: "calc(100% + 100px) !important",
              justifyContent: "flex-start !important",
            },
          }}
        >
          <img
            className="img  img-long img-maxh1"
            src="/dark/assets/imgs/works/projects/web/misctrlrsg/02.webp"
            alt="dashten"
          />
        </div>
      </div>
      <Lottie
        id="slide-lottie-img1"
        animationData={SlideRightLottie}
        loop={true}
      />

      <div className="container-fluid rest">
        <div className="col-md-12">
          <div className="text-center mt-120 mb-15">
            <p>Overview</p>
          </div>
        </div>
        <Swiper
          modules={[Navigation, Pagination, Parallax]}
          //navigation
          pagination={false}
          parallax
          spaceBetween={0}
          centeredSlides={true}
          loop={true}
          className="project2-swiper"
        >
          <SwiperSlide className="slide-maxh1" style={{ marginRight: "0" }}>
            <div className="img img-maxh1-wrapper">
              <img
                className="slide-img-maxh1"
                src="/dark/assets/imgs/works/projects/web/misctrlrsg/01.webp"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-maxh1" style={{ marginRight: "0" }}>
            <div className="img img-maxh1-wrapper">
              <img
                className="slide-img-maxh1"
                src="/dark/assets/imgs/works/projects/web/misctrlrsg/02.webp"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-maxh1" style={{ marginRight: "0" }}>
            <div className="img img-maxh1-wrapper">
              <img
                className="slide-img-maxh1"
                src="/dark/assets/imgs/works/projects/web/misctrlrsg/03.webp"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-maxh1" style={{ marginRight: "0" }}>
            <div className="img img-maxh1-wrapper">
              <img
                className="slide-img-maxh1"
                src="/dark/assets/imgs/works/projects/web/misctrlrsg/04.webp"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-maxh1" style={{ marginRight: "0" }}>
            <div className="img img-maxh1-wrapper">
              <img
                className="slide-img-maxh1"
                src="/dark/assets/imgs/works/projects/web/misctrlrsg/05.webp"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
        </Swiper>
        <Lottie
          id="slide-lottie-img2"
          animationData={SlideRightLottie}
          loop={true}
          style={{ marginTop: "-25px" }}
        />
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="cont mt-60">
              <div className="row mb-0">
                <div className="col-md-3 sm-mb30">
                  <h6>Framework</h6>
                </div>
                <div className="col-lg-6 col-md-9">
                  <div className="text">
                    <p>PHP, MySQL, WHM, CPanel, AWS-Lightsail</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 sm-mb30"></div>
                <div className="col-lg-6 col-md-9">
                  <div className="text"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Header;
