import { useEffect } from "react";
import styles from "./bg-razor.module.css";

const BgRazor = () => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div className={styles.bgrazor} id="bg-razor">
      <img
        className={styles.vectorrazorIcon}
        alt="svg-element"
        id="VectorRazor01"
        src="/dark/assets/imgs/nav/vectorrazor.svg"
        data-animate-on-scroll
      />
      <img
        className={styles.vectorrazorIcon1}
        alt="svg-element"
        id="VectorRazor02"
        src="/dark/assets/imgs/nav/vectorrazor1.svg"
        data-animate-on-scroll
      />
    </div>
  );
};

export default BgRazor;
