import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function TransToTop({ children }) {
  const { pathname } = useLocation();

  useEffect(() => {
    // Function to handle the scroll-to-top functionality
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    };

    // Function to simulate the click event on the progressWrap element
    const triggerProgressWrapClick = () => {
      const progressWrap = document.querySelector(".progress-wrap");
      if (progressWrap) {
        progressWrap.click();
      }
    };

    // Function to ensure the 'topofpage' ID is set on the first section element
    const setTopOfPageId = () => {
      const firstSection = document.querySelector("section");
      if (firstSection) {
        firstSection.setAttribute("id", "topofpage");
      }
    };

    // Function to disable horizontal scroll and related GSAP animations
    const disableHorizontalScroll = () => {
      ScrollTrigger.getAll().forEach((trigger) => {
        if (
          trigger.trigger &&
          trigger.trigger.classList.contains("showcases")
        ) {
          trigger.disable();
        }
      });
    };

    // Function to re-enable horizontal scroll and related GSAP animations
    const enableHorizontalScroll = () => {
      ScrollTrigger.getAll().forEach((trigger) => {
        if (
          trigger.trigger &&
          trigger.trigger.classList.contains("showcases")
        ) {
          trigger.enable();
        }
      });
    };

    // Ensure all other JS effects are overruled by using setTimeout
    setTimeout(() => {
      disableHorizontalScroll(); // Disable horizontal scroll during transition
      scrollToTop();
      triggerProgressWrapClick();
      setTopOfPageId();

      // Re-enable horizontal scroll after the transition ends
      setTimeout(enableHorizontalScroll, 500); // Adjust timeout duration as needed
    }, 0); // Set timeout to 0 ensures this executes after any synchronous scripts
  }, [pathname]); // Dependency array includes `pathname` to trigger on route change

  return children || null;
}
