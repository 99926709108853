"use client";
import React, { useLayoutEffect } from "react";
import { gsap } from "gsap";
import { Navigation, Pagination, Parallax } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import Lottie from "lottie-react";
import SlideRightLottie from "../../../animation/Slide-Lottie2.json";

function Header() {
  useLayoutEffect(() => {
    const tl = gsap.timeline();
    tl.fromTo(".header", { y: 200 }, { y: 0 }, "+=0.5");
    tl.fromTo(
      ".header .container-xl",
      { opacity: 0, translateY: 40 },
      { opacity: 1, translateY: 0 },
      "-=0"
    );

    // Cleanup function
    return () => tl.kill();
  }, []);

  return (
    <section
      className="header header-project2 section-padding pb-0"
      id="topofpage"
    >
      <div className="container-xl mt-80 mb-80">
        <div className="row align-items-end">
          <div className="col-lg-6">
            <div className="full-width mb-30">
              <h6 className="mb-0 main-color">Category of Project</h6>
              <h2 className="mb-10">Project Title</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
              <h6 className="mt-30 main-color">Live Url:</h6>
              <a
                href="/archive"
                target="_blank"
                rel="noreferrer"
                style={{ letterSpacing: "1px" }}
              >
                project-url.com
              </a>
            </div>
          </div>
          <div className="col-lg-5 offset-lg-1">
            <div className="info">
              <div className="row">
                <div className="col-md-6">
                  <div className="item mb-30">
                    <span className="opacity-8 mb-5 fz-12">Category :</span>
                    <h6>Design_Discipline</h6>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="item mb-30">
                    <span className="opacity-8 mb-5 fz-12">Client :</span>
                    <h6>Client_Company</h6>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="item mb-30">
                    <span className="opacity-8 mb-5 fz-12">Start Date :</span>
                    <h6>Month_Year</h6>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="item">
                    <span className="opacity-8 mb-5 fz-12">Company:</span>
                    <h6>
                      Company_Name
                      <br />
                      <p className=" fz-12 fw-500">- Position_in_Company</p>
                    </h6>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="item">
                    <span className="mb-5">Deliverables:</span>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid rest mb-30">
        <div
          className="img img-long  white-bg"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="video-frame">
            {/* <img
              id="tab-5"
              className="bg-img tab-im maxh1g"
              src="/dark/assets/imgs/works/projects/indus/anza/01.webp"
              alt="hero img"
            /> */}
            <video
              id="tab-5"
              className="bg-img tab-img"
              src="/dark/assets/imgs/works/projects/indus/anza/T01.webm"
              autoPlay
              loop
              muted
              width="100%"
              style={{
                maxWidth: "1460px",
                maxHeight: "1290px",
                filter: "brightness(1.2)",
                mixBlendMode: "darken",
              }}
            />
          </div>
        </div>
        <div className="container-fluid rest bg-black">
          <div className="col-md-12">
            <div className="text-center mb-30">
              <p className="white-bg text-black" style={{ padding: "1rem" }}>
                ANZ 6623 ATM Final Banding
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid rest">
        <div className="col-md-12">
          <div className="text-center mt-120 mb-30">
            <p>The process:</p>
          </div>
        </div>
        <Swiper
          modules={[Navigation, Pagination, Parallax]}
          //navigation
          pagination={false}
          parallax
          spaceBetween={0}
          centeredSlides={true}
          loop={true}
          className="project2-swiper"
          //style={{ overflow: "visible", maxWidth: "50%" }}
        >
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img maxh1">
              <img
                src="/dark/assets/imgs/works/projects/indus/anza/01.webp"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img maxh1">
              <img
                src="/dark/assets/imgs/works/projects/indus/anza/02.webp"
                alt="Slide 2"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img maxh1">
              <img
                src="/dark/assets/imgs/works/projects/indus/anza/03.webp"
                alt="Slide 3"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img maxh1">
              <img
                src="/dark/assets/imgs/works/projects/indus/anza/04.webp"
                alt="Slide 4"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img maxh1">
              <img
                src="/dark/assets/imgs/works/projects/indus/anza/05.webp"
                alt="Slide 4"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img maxh1">
              <img
                src="/dark/assets/imgs/works/projects/indus/anza/06.webp"
                alt="Slide 4"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img maxh1">
              <img
                src="/dark/assets/imgs/works/projects/indus/anza/07.webp"
                alt="Slide 4"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img maxh1">
              <img
                src="/dark/assets/imgs/works/projects/indus/anza/08.webp"
                alt="Slide 4"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img maxh1">
              <img
                src="/dark/assets/imgs/works/projects/indus/anza/09.webp"
                alt="Slide 4"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img maxh1">
              <img
                src="/dark/assets/imgs/works/projects/indus/anza/10.webp"
                alt="Slide 4"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img maxh1">
              <img
                src="/dark/assets/imgs/works/projects/indus/anza/11.webp"
                alt="Slide 4"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img maxh1">
              <img
                src="/dark/assets/imgs/works/projects/indus/anza/13.webp"
                alt="Slide 4"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img maxh1">
              <img
                src="/dark/assets/imgs/works/projects/indus/anza/14.webp"
                alt="Slide 4"
              />
            </div>
          </SwiperSlide>
        </Swiper>
        <Lottie
          id="slide-lottie-img2"
          animationData={SlideRightLottie}
          loop={true}
          style={{ marginTop: "-25px" }}
        />
      </div>
    </section>
  );
}

export default Header;
