"use client";
import React from 'react';



function About() {
  return (
    <section className="about">
      <div className="container section-padding bord-thin-top">
        <div className="row md-marg">
          <div className="col-lg-6">
            <div className="cont md-mb50">
              <h6 className="sub-title main-color mb-15">The opportunity</h6>
              <h3>
                If you are looking for an experienced and creative professional
                to lead the design team or to take on a challenging project,
                feel free to contact me for a chat.
              </h3>
              <div className="d-flex align-items-end mt-100">
                <div>
                  <a
                    href="mailto:hadizainal@designsdusted.com"
                    className="d-flex align-items-end"
                  >
                    <span className="sub-title nowrap mb-20">Contact Me</span>
                    <span className="fz-70 line-height-45 ti-arrow-top-right"></span>
                  </a>
                </div>
                <div className="ml-80">
                  <p>
                    No matter the task size, I can deliver quality design assets
                    and solutions to elevate you brand pressense and marketing
                    platforms.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="img-vid">
              <img
                src="dark/assets/imgs/intro/2.jpg"
                alt=""
                style={{
                  display: "block",
                  position: "relative",
                  zIndex: -1,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
