import React, { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebase";
import { signOut } from "firebase/auth";
import $ from "jquery";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { Link } from "react-router-dom";

function MenuItem03({ handleClose }) {
  const [user] = useAuthState(auth);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      handleClose(); // Close the menu drawer on logout
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  useEffect(() => {
    $(document).ready(function () {
      $(".hamenu .menu-links .main-menu > li").on("mouseenter", function () {
        $(this).removeClass("hoverd").siblings().addClass("hoverd");
      });

      $(".hamenu .menu-links .main-menu > li").on("mouseleave", function () {
        $(this).removeClass("hoverd").siblings().removeClass("hoverd");
      });

      $(".main-menu > li .dmenu").on("click", function () {
        $(this)
          .parent()
          .parent()
          .find(".sub-menu")
          .toggleClass("sub-open")
          .slideToggle();
        $(this).toggleClass("dopen");
      });

      $(".sub-menu > ul > li .sub-dmenu").on("click", function () {
        $(this)
          .parent()
          .parent()
          .find(".sub-menu2")
          .toggleClass("sub-open")
          .slideToggle();
        $(this).toggleClass("dopen");
      });

      return () => {
        $(".hamenu .menu-links .main-menu > li").off("mouseenter mouseleave");
        $(".main-menu > li .dmenu").off("click");
        $(".sub-menu > ul > li .sub-dmenu").off("click");
      };
    });
  }, []);

  return (
    <div className="hamenu open" id="navMenuItems02">
      <div className="logo icon-img-100">
        <img src="/dark/assets/imgs/logo-light.png" alt="" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-2">
            <div className="menu-text">
              <div className="text" style={{ color: "yellowgreen" }}>
                <h2>Menu</h2>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="menu-links">
              <ul className="main-menu rest">
                <li>
                  <div className="o-hidden">
                    <Link to="/" className="link" onClick={handleClose}>
                      <span className="fill-text" data-text="Home">
                        Home
                      </span>
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="o-hidden">
                    <Link to="/services" className="link" onClick={handleClose}>
                      <span className="fill-text" data-text="Services">
                        Services
                      </span>
                    </Link>
                  </div>
                </li>

                <li>
                  <div className="o-hidden">
                    <Link to="/archives" className="link" onClick={handleClose}>
                      <span className="fill-text" data-text="Archives">
                        Archives
                      </span>
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="o-hidden">
                    <Link to="/blogs" className="link" onClick={handleClose}>
                      <span className="fill-text" data-text="Blogs">
                        Blogs
                      </span>
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="o-hidden">
                    <Link
                      to="/design-journey"
                      className="link"
                      onClick={handleClose}
                    >
                      <span className="fill-text" data-text="Design Journey">
                        Design Journey
                      </span>
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="o-hidden">
                    <Link to="/about" className="link" onClick={handleClose}>
                      <span className="fill-text" data-text="About">
                        About
                      </span>
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="cont-info">
              <div className="item mb-50">
                <h6 className="sub-title mb-15 opacity-7">
                  Access Full Content
                </h6>
                {/* <li className="mb-10">
                    <Link to="/login" className="hover-this">
                      <span className="hover-anim">Login</span>
                    </Link>
                  </li> */}
                {user ? (
                  <li>
                    <button
                      className="hover-this link-text"
                      onClick={handleLogout}
                      style={{
                        color: "red",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        padding: "0",
                      }}
                    >
                      Logout
                    </button>
                  </li>
                ) : (
                  <li>
                    <Link
                      to="/login"
                      className="hover-this link-text"
                      onClick={handleClose}
                    >
                      Login
                    </Link>
                  </li>
                )}
                <li className="mb-10">
                  <Link
                    to="/register"
                    className="hover-this link-text"
                    onClick={handleClose}
                  >
                    <span className="hover-anim">Request Access</span>
                  </Link>
                </li>
              </div>
              <div className="item mb-50">
                <h6 className="sub-title mb-15 opacity-7">Social Media</h6>
                <ul className="rest social-text">
                  <li className="mb-10">
                    <a href="#0" className="hover-this" onClick={handleClose}>
                      <span className="hover-anim">Pinterest</span>
                    </a>
                  </li>
                  <li className="mb-10">
                    <a href="#0" className="hover-this" onClick={handleClose}>
                      <span className="hover-anim">LinkedIn</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="item mb-40">
                <h6 className="sub-title mb-15 opacity-7">Collaboration?</h6>
                <h5>
                  <a href="mailto:hadizainal@designsdusted.com">
                    Get-in-touch
                  </a>
                </h5>
              </div>
              <div
                className="item mb-40"
                style={{ scale: "0.85", transformOrigin: "left top" }}
              >
                <p className="sub-title mb-10 opacity-7">
                  <WarningAmberRoundedIcon /> Disclaimer
                </p>
                <p className="rest">
                  This platform showcases my diverse professional experiences
                  and achievements; artifacts are for demonstration only, not
                  replication.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MenuItem03;
