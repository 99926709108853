/* eslint-disable react/prop-types */
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './button-nav-main-services.module.css';
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";

const ButtonNavMainServices = ({ isActive }) => {
  const navigate = useNavigate();

  const onButtonNavMainClick = useCallback(() => {
    navigate('/services');
  }, [navigate]);

  return (
    <Tooltip
      className={styles.tooltip}
      title="Services"
      placement="top-start"
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 200 }}
      id="tooltip-mainmenu"
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [30, -8],
              },
            },
          ],
        },
      }}
    >
      <button
        className={`${styles.buttonNavmain} ${isActive ? styles.active : ""}`}
        id="HomeButton"
        onClick={onButtonNavMainClick}
      >
        <img
          className={styles.bubblesubtract01Icon}
          alt="svg"
          id="svg-subtract-home"
          src="/dark/assets/imgs/nav/bubblesubtract01.svg"
        />
        <img
          className={styles.iconhome}
          alt="icon"
          id="icon-service"
          src="/dark/assets/imgs/nav/iconservice.svg"
        />
      </button>
    </Tooltip>
  );
};

export default ButtonNavMainServices;
