"use client";
import { Helmet } from "react-helmet";
//import LoadingScreen from "../common/loader";
import ProgressScroll from "../components/default/ProgressScroll";
import ArchiveHeader from "../components/default/ArchiveHeader";
import Portfolio from "../components/default/portfolio-gallery/Portfolio";
import Footer from "../components/default/Footer";
import ArvhiveFilter from "../components/default/ArchiveFilter";

export default function Blogs() {
  console.clear();
  return (
    <>
      <Helmet>
        <title>Archives | Design Vault</title>
        <link rel="icon" href="/dark/imgs/nav/favicon.ico" />
        <link rel="shortcut icon" href="/dark/imgs/nav/favicon.ico" />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/plugins.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/style.css"
        />
      </Helmet>
      <div>
        <ProgressScroll />
        <div id="smooth-wrapper">
          <div id="smooth-content">
            {/* Fixed Container */}
            <div className="fixed-container">
              <ArvhiveFilter />
              <main className="main-bg">
                <ArchiveHeader />
                <Portfolio />
              </main>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
console.clear();
