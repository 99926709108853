import React, { /* useEffect */ } from "react";
/* import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer"; */

/* const HzSlideup = ({ children }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  return (
    <motion.div
      className="hz-slideup"
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
    >
      {children.map((child, index) => (
        <motion.div key={index} variants={textVariants}>
          {child}
        </motion.div>
      ))}
    </motion.div>
  );
}; */
function Intro() {
  return (
    <>
      <section className="intro section-padding position-re">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-6">
              <div className="cont">
                <h6 className="hz-slideup-text">
                  <span className="sup-text">
                    20 years of diverse design experience enhance my skills,
                    helping businesses innovate, stand out, and stay competitive
                    in today's dynamic market.
                  </span>
                </h6>
                {/* <HzSlideup>
                  <motion.h6 className="hz-slideup-text">
                    <span className="sup-text">
                      20 years of diverse design experience enhance my skills,
                    </span>
                  </motion.h6>
                  <motion.h6 className="hz-slideup-text mt-2">
                    <span className="sup-text">
                      helping businesses innovate, stand out, and stay
                      competitive
                    </span>
                  </motion.h6>
                  <motion.h6 className="hz-slideup-text mt-2">
                    <span className="sup-text">in today's dynamic market.</span>
                  </motion.h6>
                </HzSlideup> */}
                <div className="exp mt-80 md-mb15">
                  <h2 className="fz-70 numb-font" style={{ fontWeight: "700" }}>
                    20+{" "}
                    <span
                      id="twentyplus-sub-text"
                      className="sub-title main-font opacity-7"
                      style={{
                        position: "relative",
                        fontWeight: "300",
                        top: "-8px",
                      }}
                    >
                      Years of Cross-Industry
                      <br></br>
                      Design Experience
                    </span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="text">
                <p>
                  Welcome to my curated collection, a showcase of diverse
                  experiences and artifacts spanning professional,
                  entrepreneurial, academic, community, and personal realms.
                  Here, I illustrate my expertise and achievements,
                  demonstrating my design professionalism and readiness to
                  embrace new industry challenges. My current focus is on UI-UX
                  design, Web App Design & Development, Branding & Identity, and
                  Digital Marketing.
                </p>
              </div>
              <div className="main-marq o-hidden mt-30">
                <div className="slide-har st1">
                  <div className="box">
                    <div className="item">
                      <h4 className="d-flex align-items-center">
                        <span>Web App Design</span>
                        <span className="fz-50 ml-50 stroke icon">*</span>
                      </h4>
                    </div>
                    <div className="item">
                      <h4 className="d-flex align-items-center">
                        <span>Industrial Design</span>
                        <span className="fz-50 ml-50 stroke icon">*</span>
                      </h4>
                    </div>
                    <div className="item">
                      <h4 className="d-flex align-items-center">
                        <span>Interior Design</span>
                        <span className="fz-50 ml-50 stroke icon">*</span>
                      </h4>
                    </div>
                    <div className="item">
                      <h4 className="d-flex align-items-center">
                        <span>Post-Productions</span>
                        <span className="fz-50 ml-50 stroke icon">*</span>
                      </h4>
                    </div>
                    <div className="item">
                      <h4 className="d-flex align-items-center">
                        <span>Motion Graphics</span>
                        <span className="fz-50 ml-50 stroke icon">*</span>
                      </h4>
                    </div>
                    <div className="item">
                      <h4 className="d-flex align-items-center">
                        <span>Digital Marketing</span>
                        <span className="fz-50 ml-50 stroke icon">*</span>
                      </h4>
                    </div>
                    <div className="item">
                      <h4 className="d-flex align-items-center">
                        <span>Branding Design</span>
                        <span className="fz-50 ml-50 stroke icon">*</span>
                      </h4>
                    </div>
                    <div className="item">
                      <h4 className="d-flex align-items-center">
                        <span>Visual Communication</span>
                        <span className="fz-50 ml-50 stroke icon">*</span>
                      </h4>
                    </div>
                    <div className="item">
                      <h4 className="d-flex align-items-center">
                        <span>UI | UX</span>
                        <span className="fz-50 ml-50 stroke icon">*</span>
                      </h4>
                    </div>
                  </div>
                  <div className="box">
                    <div className="item">
                      <h4 className="d-flex align-items-center">
                        <span>Web App Design</span>
                        <span className="fz-50 ml-50 stroke icon">*</span>
                      </h4>
                    </div>
                    <div className="item">
                      <h4 className="d-flex align-items-center">
                        <span>Industrial Design</span>
                        <span className="fz-50 ml-50 stroke icon">*</span>
                      </h4>
                    </div>
                    <div className="item">
                      <h4 className="d-flex align-items-center">
                        <span>Interior Design</span>
                        <span className="fz-50 ml-50 stroke icon">*</span>
                      </h4>
                    </div>
                    <div className="item">
                      <h4 className="d-flex align-items-center">
                        <span>Post-Productions</span>
                        <span className="fz-50 ml-50 stroke icon">*</span>
                      </h4>
                    </div>
                    <div className="item">
                      <h4 className="d-flex align-items-center">
                        <span>Motion Graphics</span>
                        <span className="fz-50 ml-50 stroke icon">*</span>
                      </h4>
                    </div>
                    <div className="item">
                      <h4 className="d-flex align-items-center">
                        <span>Digital Marketing</span>
                        <span className="fz-50 ml-50 stroke icon">*</span>
                      </h4>
                    </div>
                    <div className="item">
                      <h4 className="d-flex align-items-center">
                        <span>Branding Design</span>
                        <span className="fz-50 ml-50 stroke icon">*</span>
                      </h4>
                    </div>
                    <div className="item">
                      <h4 className="d-flex align-items-center">
                        <span>Visual Communication</span>
                        <span className="fz-50 ml-50 stroke icon">*</span>
                      </h4>
                    </div>
                    <div className="item">
                      <h4 className="d-flex align-items-center">
                        <span>UI | UX</span>
                        <span className="fz-50 ml-50 stroke icon">*</span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="line-overlay">
          <svg
            viewBox="0 0 1728 1101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M-43 773.821C160.86 662.526 451.312 637.01 610.111 733.104C768.91 829.197 932.595 1062.9 602.782 1098.75C272.969 1134.6 676.888 25.4306 1852 1"
              style={{ strokeDasharray: 3246.53 }}
            ></path>
          </svg>
        </div>
      </section>
    </>
  );
}

export default Intro;
