import React from 'react';

function Number() {
  return (
    <section className="numbers">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-3 col-md-3 exp-number-col">
            <div className="item d-flex align-items-center justify-content-center">
              <h2 className="fz-60 line-height-1 w150">40+</h2>
              <span className="opacity-7 ml-10 num-desctext">
                Branding & <br /> Digital Platforms
              </span>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 exp-number-col">
            <div className="item d-flex align-items-center justify-content-center">
              <h2 className="fz-60 line-height-1 w150">80+</h2>
              <span className="opacity-7 ml-10  num-desctext">
                Industrial Design
                <br /> Projects
              </span>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 exp-number-col">
            <div className="item d-flex align-items-center justify-content-center">
              <h2 className="fz-60 line-height-1 w150">25k+</h2>
              <span className="opacity-7 ml-10 num-desctext">
                sqf of Commercial <br />
                Interior Projects
              </span>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 exp-number-col">
            <div className="item d-flex align-items-center justify-content-center">
              <h2 className="fz-60 line-height-1 w150">50+</h2>
              <span className="opacity-7 ml-10 num-desctext">
                Post-Production & <br /> Motion Graphics
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Number;
