"use client";
import React from "react";

function SkillsTech() {
  return (
    <div className="my-skills2 mt-120">
      <div className="container">
        <div className="sec-head text-center mb-80">
          <h6 className="sub-title main-color mb-0">Tech Skills</h6>
          <h2>
            Preferred <span className="fw-200">Frameworks</span>
          </h2>
        </div>
        <div className="row md-marg justify-content-center">
          {/*  */}
          <div className="col-lg-2 col-md-4 col-6">
            <div className="item mb-30">
              <div className="main-bg">
                <div className="img">
                  <img src="/dark/assets/imgs/resume/icon-html.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="col-lg-2 col-md-4 col-6">
            <div className="item mb-30">
              <div className="main-bg">
                <div className="img">
                  <img src="/dark/assets/imgs/resume/icon-js.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="col-lg-2 col-md-4 col-6">
            <div className="item mb-30">
              <div className="main-bg">
                <div className="img">
                  <img src="/dark/assets/imgs/resume/icon-css.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="col-lg-2 col-md-4 col-6">
            <div className="item mb-30">
              <div className="main-bg">
                <div className="img">
                  <img src="/dark/assets/imgs/resume/icon-react.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="col-lg-2 col-md-4 col-6">
            <div className="item mb-30">
              <div className="main-bg">
                <div className="img">
                  <img src="/dark/assets/imgs/resume/icon-next.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="col-lg-2 col-md-4 col-6">
            <div className="item mb-30">
              <div className="main-bg">
                <div className="img">
                  <img src="/dark/assets/imgs/resume/icon-react3.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="col-lg-2 col-md-4 col-6">
            <div className="item mb-30">
              <div className="main-bg">
                <div className="img">
                  <img src="/dark/assets/imgs/resume/icon-php.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="col-lg-2 col-md-4 col-6">
            <div className="item mb-30">
              <div className="main-bg">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/resume/icon-codeignighter.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="col-lg-2 col-md-4 col-6">
            <div className="item mb-30">
              <div className="main-bg">
                <div className="img">
                  <img src="/dark/assets/imgs/resume/icon-laravel.png" alt="" />
                </div>
              </div>
            </div>
          </div>

          {/*  */}
        </div>
      </div>
    </div>
  );
}

export default SkillsTech;
