// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAhv0gYfH9Qe9LSiQHFBqSRLuBdaYDT-a4",
  authDomain: "hz2024-f4913.firebaseapp.com",
  databaseURL:
    "https://hz2024-f4913-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "hz2024-f4913",
  storageBucket: "hz2024-f4913.appspot.com",
  messagingSenderId: "1098030192599",
  appId: "1:1098030192599:web:0e7f65d3d3469d11d81d92",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
