"use client";
import { Helmet } from "react-helmet";
import LoadingScreen from "../common/loader";
import ProgressScroll from "../components/default/ProgressScroll";
import DesignJourHead from "../components/default/DesignJourHead";
import DesignJour03 from "../components/default/DesignJour03";
import ImageScale from "../components/default/ImageScale";
import Footer from "../components/default/Footer";
import DesignJourHeader from "../components/default/DesignJourHeader";

export default function DesignJourney() {
  return (
    <>
      <Helmet>
        <title>Archives | Design-Journey</title>
        <link rel="icon" href="/dark/imgs/nav/favicon.ico" />
        <link rel="shortcut icon" href="/dark/imgs/nav/favicon.ico" />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/plugins.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/style.css"
        />
      </Helmet>
      <body>
        <LoadingScreen />
        <ProgressScroll />
        <div id="smooth-wrapper">
          <div id="smooth-content">
            <main className="main-bg">
              <DesignJourHeader />
              <DesignJourHead />
              <DesignJour03 />
              <ImageScale />
            </main>
            <Footer />
          </div>
        </div>
      </body>
    </>
  );
}
console.clear();
if (!console.suppress) {
  console.warn("Console has been cleared!");
}