"use client";
import React from "react";

function DesignJour03() {
  return (
    <div className="work-sticky section-padding pt-0">
      <div className="container-xxl container">
        <div className="row">
          {/* 01 masbro */}
          <div className="col-lg-8 items">
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/masbro-1.png" alt="" />
            </div>
          </div>
          <div className="col-lg-4 items">
            <div className="cont">
              <div className="sticky-item">
                <p className="main-color  mb-10">
                  My Design Journey | My Singapore Chapter
                </p>
                <h3 className="mb-15 fw-800">
                  Late nights, coffee stains, restless minds
                </h3>
                <p>
                  As designers, we navigate unique challenges, acquiring diverse
                  skills along the way. Life's unpredictability demands
                  resilience, and our design capabilities become our sharpest
                  tools. Grateful for shared knowledge, growth opportunities,
                  and humbling challenges.
                </p>
              </div>
            </div>
          </div>
          {/* 02 events */}
          <div className="col-lg-4 items">
            <div className="cont">
              <div className="sticky-item">
                <p className="main-color  mb-10">Since 2000</p>
                <h3 className="mb-15 fw-800">Event Flyers</h3>
                <p>
                  The web1.0 era, where the world still heavily rely on
                  print-marketing.
                </p>
                <br />
                <p>
                  'Hype' street-wear stores would lay out flyers of upcoming
                  events and parties at their entrances. For design students,
                  like myself in the early 2000s, it was a great opportunity to
                  showcase our skills and get network exposure. Hence, my early
                  hustles to get some income.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-8 items">
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/2.jpg" alt="" />
            </div>
          </div>
          {/* 03 pid*/}
          <div className="col-lg-8 items">
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/3.jpg" alt="" />
            </div>
          </div>
          <div className="col-lg-4 items">
            <div className="cont">
              <div className="sticky-item">
                <p className="main-color  mb-10">2002 - 2005</p>
                <h3 className="mb-15 fw-800">Product & Industrial Design</h3>
                <p>
                  3 years of Tertiary Education in Product and Industrial Design
                  was a transformative journey, enhancing my creativity,
                  technical skills, and understanding of user-centered
                  solutions.
                </p>
              </div>
            </div>
          </div>
          {/* 04 afterlife */}
          <div className="col-lg-4 items">
            <div className="cont">
              <div className="sticky-item">
                <p className="main-color  mb-10">2003 - 2004</p>
                <h3 className="mb-15 fw-800">Streetwear Apparels</h3>
                <p>
                  Afterlife Clothing
                  <br />
                  <br />
                  From trying to negotiate a consignment deal with 'afterlife'
                  store owner to place my labels on their shelves, became a
                  store rebranding project. I designed their first 2 seasons of
                  apparel and accessories. Each season with its own narrative.
                  This was one of my early experiences in textile supplier
                  sourcing, screen-print industry and photography.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-8 items">
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/4.jpg" alt="" />
            </div>
          </div>
          {/* 05 Burn*/}
          <div className="col-lg-8 items">
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/5.jpg" alt="" />
            </div>
          </div>
          <div className="col-lg-4 items">
            <div className="cont">
              <div className="sticky-item">
                <p className="main-color  mb-10">2004 - 2005</p>
                <h3 className="mb-15 fw-800">Burn Magazine</h3>
                <p>
                  Around late 2004, the events company I was freelancing for,
                  offered partnership to start a free street culture magazine.
                  As he already had 3 investors onboard, I was tasked to
                  assemble a team of designers, photographers and a network
                  contributors to get the magazine up and running.
                </p>
                <br />
                <img src="/dark/assets/imgs/works/stand/5a.jpg" alt="" />
              </div>
            </div>
          </div>
          {/* 06 army*/}
          <div className="col-lg-4 items">
            <div className="cont">
              <div className="sticky-item">
                <p className="main-color  mb-10">2005 - 2007 | Call of Duty</p>
                <h3 className="mb-15 fw-800">Singapore Armed Forces</h3>
                <p>
                  Regimental life does nurture discipline and the importance of
                  teamwork amongst other things.
                  <br />
                  Yet, as a creative rebel, I had to leave my mark. I submitted
                  my entry and won the SAF Transport Hub East Logo Design
                  Competition. The formation badge is still in use today.
                  <br />
                  <br />
                  During my service, I'd take on any design tasks to keep my
                  skills relavant to the industry. From designing logos and
                  organising my unit 'Ninja Company' polo-ts to assisting in
                  powerpoints presentations for the higher-ups.
                  <br />
                  <br />I also assisted in some interior renovation work on my
                  off days.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-8 items">
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/6.jpg" alt="" />
            </div>
          </div>
          {/* 07 kinemat */}
          <div className="col-lg-8 items">
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/7.jpg" alt="" />
            </div>
          </div>
          <div className="col-lg-4 items">
            <div className="cont">
              <div className="sticky-item">
                <p className="main-color  mb-10">2008 - 2009</p>
                <h3 className="mb-15 fw-800">Events and Production</h3>
                <p>
                  KINEMAT
                  <br />
                  At Kinemat, the 'Work hard, play hard' ethos shaped my growth.
                  Reflecting on organizing corporate events, music festivals,
                  and interacting with international celebrities, I smile. Key
                  experiences include Sony Ericsson Traffic Jams parties with
                  'Arrested Development,' managing Beastie Boys logistics, and
                  coordinating Heineken rooftop parties. This time offered
                  invaluable insights into the events industry.
                </p>
                <br />
                <video
                  src="/landing-preview/video/kin01.webm"
                  //poster="/landing-preview/img/showcase/8.jpg"
                  //controls
                  height={"auto"}
                  width={"100%"}
                  autoPlay={true}
                  loop={true}
                  muted={true}
                />
              </div>
            </div>
          </div>
          {/* 08 121view*/}
          <div className="col-lg-5 items">
            <div className="cont">
              <div className="sticky-item">
                <p className="main-color  mb-10">2008...</p>
                <h3 className="mb-15 fw-800">
                  Post-Production <br />& Motion Graphics
                </h3>
                <p>
                  While working at Kinemat and collaborating with the German
                  Visual Artist 'Pathfindarei,' I delved into the realms beyond
                  vectors, exploring motion graphics and post-production. I
                  secured contract work from a company specialising in media
                  advertising for shopping malls.
                  <br />
                  <br />
                  My ventures into post-production and motion graphics proved
                  transformative. Mastering the art of visual storytelling, this
                  period equipped me with essential skills that extended beyond
                  creative realms into marketing and web development. As
                  technology advanced, the knowledge gained during those early
                  projects became instrumental in navigating a dynamic and
                  today's evolving digital landscape.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-7 items">
            <div className="img black-bg">
              <video
                src="/landing-preview/video/121view01.webm"
                //poster="/landing-preview/img/showcase/8.jpg"
                //controls
                height={"auto"}
                width={"100%"}
                autoPlay={true}
                loop={true}
                muted={true}
              />
            </div>
          </div>
          {/* 09 commit */}
          <div className="col-lg-5 items">
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/9a.jpg" alt="" />
            </div>
          </div>
          <div className="col-lg-7 items">
            <div className="cont">
              <div className="sticky-item">
                <p className="main-color  mb-10">2008...</p>
                <h3 className="mb-15 fw-800">Letting go...</h3>
                <p>
                  While not directly related to design, there was a pivotal
                  moment in my personal and professional growth when I chose to
                  part ways with two cherished activities from my younger years
                  – skateboarding and breakdancing. In 2005, I gained
                  sponsorship from Nike, followed by a couple of local fashion
                  labels. Although the popularity garnered played a role in
                  securing design opportunities, my deeper commitment was to the
                  evolution of design itself. This decision marked a significant
                  juncture that would test and shape my journey in the years to
                  come.
                </p>
              </div>
            </div>
          </div>
          {/* 10 aus uni */}
          <div className="col-lg-4 items">
            <div className="cont">
              <div className="sticky-item">
                <p className="main-color  mb-10">
                  2009 - 2010 | My Australia Chapter
                </p>
                <h3 className="mb-15 fw-800">
                  B.A Design
                  <p className="main-color  mb-10">
                    Industrial Design / Interior Design
                  </p>
                </h3>
                <p>
                  During my university years, I deepened my expertise in
                  industrial and interior design, building on my diploma in
                  product and industrial design to master both disciplines.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-8 items">
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/10.jpg" alt="" />
            </div>
          </div>
          {/* 11 unmute */}
          <div className="col-lg-8 items">
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/11.jpg" alt="" />
            </div>
          </div>
          <div className="col-lg-4 items">
            <div className="cont">
              <div className="sticky-item">
                <p className="main-color  mb-10">2010...</p>
                <h3 className="mb-15 fw-800">
                  Remain Calm & Repeat Process...
                  <p className="main-color  mb-10">"Forming a new network"</p>
                </h3>
                <p>
                  As I embarked on a new chapter of my journey, it was the ideal
                  moment to satiate my creative impulses. Acknowledging the need
                  to secure design projects once again, I faced the challenge of
                  building exposure in a foreign land. Juggling pizza
                  deliveries, studies, and a social life, I found myself
                  surrounded by a network of fellow students. Recognizing that
                  many couldn't afford my design services, I selectively chose
                  projects aligned with impactful causes, navigating the
                  complexities of being a foreigner in an unfamiliar
                  environment.
                </p>
                <br />
                <img src="/dark/assets/imgs/works/stand/11a.jpg" alt="" />
              </div>
            </div>
          </div>
          {/* 12 capabilities */}
          <div className="col-lg-6 items">
            <div className="cont">
              <div className="sticky-item">
                <p className="main-color  mb-10">
                  In our capabilities we trust
                </p>
                <h3 className="mb-15 fw-800">
                  Design is my sharpest tool...
                  <p className="main-color  mb-10">"Use it to survive!"</p>
                </h3>
                <p>
                  In the third month post-graduation, financial strain hit, and
                  I found myself grappling to meet rent payments. Depleting my
                  savings on a graduate visa, granting me two more years in
                  Australia, I faced the harsh reality that my degree didn't
                  qualify for direct permanent residency. Despite this
                  challenge, I believed in the potential for accelerated growth
                  by staying. Initiating the first version of this website
                  marked the beginning of a series of iterations over the years.
                  In the era of the transition to web 2.0, terms like UI and UX
                  were unfamiliar, and responsive design was rare. Undeterred by
                  the odds, I trusted that my capabilities held value for
                  numerous companies.
                </p>
                <br />
                <img src="/dark/assets/imgs/works/stand/12a.jpg" alt="" />
                <p>
                  Continual iterations of this online journal over the years.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 items">
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="" />
            </div>
            <div className="cont mt-30">
              <div className="sticky-item">
                <p className="main-color  mb-10">
                  Earning my Australian permanent-residency
                </p>
                <h3 className="mb-15 fw-800">
                  Against the odds...
                  <p className="main-color  mb-10">"Use it to survive!"</p>
                </h3>
                <p>
                  I hold deep gratitude for the late Paul Senior, founder of
                  Sterling Products, who believed in me and sponsored my
                  residency. To this day, I haven't encountered anyone who has
                  followed the same path to residency as I have, and I am
                  immensely thankful for this unique opportunity that came with
                  patience, resilience and hard work.
                </p>
              </div>
            </div>
          </div>
          {/* 13 sterling */}
          <div className="col-lg-8 items">
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/14.jpg" alt="" />
            </div>
          </div>
          <div className="col-lg-4 items">
            <div className="cont">
              <div className="sticky-item">
                <p className="main-color  mb-10">2010...</p>
                <h3 className="mb-15 fw-800">Industrial Designer</h3>
                <p>
                  Sterling Products
                  <br />
                  Over the subsequent years, I dedicated myself to refining
                  skills and expressed deep gratitude for those who generously
                  shared their knowledge. This journey involved mastering
                  diverse areas, from vinyl graphics to operating large CNC
                  machines, tackeling manufacturing and engineering issues,
                  challenging traditional design norms and gaining indept
                  understanding of materials and manufacturing processes.
                  Innovative approaches, enhancing production efficiency as well
                  as project tenders. I embraced opportunities for a
                  comprehensive understanding, leading to design with heightened
                  empathy. The journey extended beyond design, encompassing
                  website upgrades, marketing, and strategic segmentation of
                  company capabilities. Connecting prior knowledge, this
                  holistic approach aimed to improve outcomes strategically.
                </p>
              </div>
            </div>
          </div>
          {/* 14 masters */}
          <div className="col-lg-4 items">
            <div className="cont">
              <div className="sticky-item">
                <p className="main-color  mb-10">2014</p>
                <h3 className="mb-15 fw-800">
                  M.A Business Process Management
                </h3>
                <p>
                  In 2014, I decided to pursue a Master's degree in Business
                  Process Management, with a focus on IT and Business, while
                  working full-time. This decision was influenced by the desire
                  to gain a more comprehensive understanding of the business
                  landscape and its intricacies. The course provided a platform
                  to explore the potential of technology to enhance business
                  processes and outcomes. The knowledge gained proved invaluable
                  in navigating the digital landscape and its rapid evolution.
                  <br />
                  <br />
                  <span className="sub-title mb-15 main-color">2015</span>
                  <br />
                  After five years of professional experience, I found myself at
                  a crossroads, prompting a thorough reevaluation of my career
                  trajectory. Recognising the need for a fresh perspective and
                  seeking personal growth, I confronted the question: was I
                  accumulating five years of genuine professional development or
                  simply repeating one year of experience five times? Faced with
                  a stagnant career and rising living costs, I embarked on a
                  transformative journey. By strategically relocating to India,
                  where the Australian dollar could be stretched further, I
                  temporarily stepped away from my job. The ensuing month-long
                  backpacking expedition across India provided a valuable
                  opportunity for self-reflection, exploration, and gaining
                  clarity about my aspirations. This life-affirming experience
                  became a pivotal moment as I meticulously planned and
                  envisioned the next chapter of my professional journey.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-8 items">
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/10.jpg" alt="" />
            </div>
            <div className="img mt-30">
              <img src="/dark/assets/imgs/works/stand/21.png" alt="" />
            </div>
            <div className="cont mt-30">
              <div className="sticky-item">
                <p className="main-color  mb-10">2015</p>
                <h3 className="mb-15 fw-800">Entrepreneurship </h3>
                <p>
                  In late 2015, I met my future business partner, a former CTO
                  prioritising family over his career. His mentorship guided my
                  entrepreneurial journey, leading to the founding of Digital
                  Hustlaz. Combining his experience with my drive, we sought
                  capital for the business and living expenses, while I balanced
                  full-time work and property ownership.
                </p>
              </div>
            </div>
          </div>
          {/* 15 DH */}
          <div className="col-lg-8 items">
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/17.jpg" alt="" />
            </div>
          </div>
          <div className="col-lg-4 items">
            <div className="cont">
              <div className="sticky-item">
                <p className="main-color  mb-10">2015...</p>
                <h3 className="mb-15 fw-800">
                  Digital Hustlaz
                  <p className="main-color  mb-10">
                    Co-Founder & Creative Chief"
                  </p>
                </h3>
                <p>
                  Embarking on the journey of establishing Digital Hustlaz was a
                  profound and transformative experience. Motivated by the
                  prospect of reducing costs, the decision was made to set up
                  the company in Malaysia, navigating the challenges of an
                  unfamiliar business landscape. The initial struggle to
                  generate sufficient income prompted a relentless pursuit of
                  knowledge in coding, design, business, and marketing. This
                  multifaceted learning journey proved to be extremely
                  challenging, yet the potential unlocked by acquiring new
                  skills, experience, and capabilities was promising. The
                  endeavor involved forging and fostering partnerships,
                  emphasizing better value offerings to clients. However, the
                  anticipated scalability did not materialize as swiftly as
                  expected. In a miraculous turn of events, an interior design
                  position in Singapore was offered, despite the exhaustion of
                  juggling two jobs over the last decade. While the acquired
                  skills were priceless, the determination to learn and harness
                  knowledge remained unwavering, propelling forward on the path
                  of continuous growth.
                </p>
              </div>
            </div>
          </div>
          {/* 16 Leap */}
          <div className="col-lg-12 items">
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/18a.jpg" alt="" />
            </div>
            <div className="row mt-30">
              <div className="col-md-5">
                <p className="sub-title main-color">
                  2016 | Returning to Singapore
                </p>
                <h3 className="mb-15">Leap of Faith...</h3>
              </div>
              <div className="col-md-7">
                <p>
                  After a decade of managing dual roles, I embraced change by
                  accepting a job in Singapore to address financial concerns.
                  Motivated by a passion for design and technology, I sought a
                  balanced lifestyle and an opportunity to enhance my expertise
                  in the interior design industry. The decision to relocate was
                  also driven by a desire to be closer to family. Joining a
                  renowned firm marked a dream opportunity, prompting me to make
                  the most of the experience. This move not only marked a new
                  chapter in my professional journey but also led to a
                  significant personal milestone—meeting my future life partner.
                </p>
              </div>
            </div>
          </div>
          {/* 17 sca */}
          <div className="col-lg-4 items">
            <div className="cont">
              <div className="sticky-item">
                <p className="main-color  mb-10">2016</p>
                <h3 className="mb-15 fw-800">Interior Designer</h3>
                <p>
                  SCA Design (Ong & Ong Group)
                  <br />
                  <br />
                  As a Commercial Interior Designer in Singapore for a year, I
                  successfully managed to win projects encompassing that
                  accounts to more than 25,000 square feet of commercial spaces
                  with a cumulative project cost exceeding 3 million dollars. My
                  responsibilities spanned the entire project lifecycle, from
                  conceptual designs to winning tenders. Followed with
                  overseeing the design process, ensuring a harmonious blend of
                  functionality, aesthetic appeal, and adherence to budget
                  constraints. This experience offered invaluable insights into
                  the dynamics of large-scale project management, client
                  engagement, and navigating the intricacies of the commercial
                  interior design industry within an extremely fast-paced
                  economy.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-8 items">
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/19.jpg" alt="" />
            </div>
          </div>
          {/* 18 bris */}

          <div className="col-lg-8 items">
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/20.jpg" alt="" />
            </div>
          </div>
          <div className="col-lg-4 items">
            <div className="cont">
              <div className="sticky-item">
                <p className="main-color  mb-10">2019 | Back to BrisVegas</p>
                <h3 className="mb-15 fw-800">"The Design Hustla"</h3>
                <p>
                  Digital Hustlaz | Heather Grey
                  <br />
                  My relentless design hustle encompassed a diverse array of
                  projects, spanning web/app development, UI/UX initiatives,
                  interior design ventures, and branding projects. The journey
                  involved navigating remote pitching, providing 3D
                  visualization support, and crafting intricate construction
                  drawings. In the realm of tech support, challenges were met
                  with resilience, echoing the army adage, "Tough times don't
                  last, tough men do."
                  <br />
                  <br />
                  As the world grappled with the challenges of the COVID-19
                  pandemic, the design landscape became even more demanding. In
                  the midst of uncertainties, a silver lining emerged when one
                  of my clients extended a senior full-time position as the
                  contract concluded. This opportunity not only provided
                  stability but also afforded a chance to breathe easier amid
                  the evolving professional landscape.
                </p>
                <br />
                <br />
                <br />
                <span className="sub-title mb-15 main-color">
                  The journey continues...
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DesignJour03;
