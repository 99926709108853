"use client";
import React, { useLayoutEffect } from "react";
import { gsap } from "gsap";


function HeaderFreelancer() {

  useLayoutEffect(() => {
    const tl = gsap.timeline();
    tl.fromTo(".header", { y: 2000 }, { y: 0 }, "+=0")
      .fromTo(
        ".header .container",
        { opacity: 0, translateY: 40 },
        { opacity: 1, translateY: 0 },
        "-=0"
      )
      .fromTo(
        ".img.header",
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0 },
        "+=0.1" // Delay the animation 
      );

    // Cleanup function
    return () => tl.kill();
  }, []);

  console.clear();

  return (
    <section className="pagetop services-hero" id="topofpage">
      <div className="header freelancer theHcontainer" data-scroll-index="0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="img header">
                <img
                  className="service-ape"
                  src="/dark/assets/imgs/intro/freelancer.png"
                  alt=""
                  data-speed="1.1"
                  data-lag="0"
                  style={{
                    zIndex: "2",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="caption">
          <h1 className="up stroke" style={{ opacity: "0.4" }}>
            <span>DESIGN</span> <span>SERVICES</span>
          </h1>
          <h1
            className="down"
            style={{
              zIndex: "-1",
            }}
          >
            <span>DESIGN</span> <span>SERVICES</span>
          </h1>
          <h5 className="up secondary-title text-white fw-200">
            via{" "}
            <span>
              {" "}
              <a
                href="https://designsdusted.com"
                target="_blank"
                rel="noreferrer"
                className="main-color fw-600"
              >
                DESIGNS DUSTED
              </a>
            </span>
          </h5>
          <img
            src="/dark/assets/imgs/intro/bg-vid-smoke.png"
            alt=""
            data-speed="0.9"
            data-lag="0"
            style={{
              position: "absolute",
              left: "5%",
              top: "0",
              zIndex: "-10",
              width: "auto",
              height: "100%",
              filter: "blur(10px)",
            }}
          />
          <img
            src="/dark/assets/imgs/intro/bg-vid-smoke2.png"
            alt=""
            data-speed="0.95"
            data-lag="0"
            style={{
              position: "absolute",
              right: "5%",
              top: "0",
              zIndex: "-10",
              width: "auto",
              height: "100%",
              filter: "blur(10px) contrast(1.2) brightness(1.2)",
            }}
          />
        </div>
      </div>
    </section>
  );
}

export default HeaderFreelancer;
