import React from "react";
import { Navigation, Pagination, Parallax } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import Lottie from "lottie-react";
import SlideRightLottie from "../../../animation/Slide-Lottie2.json";
import { Link } from "react-router-dom";

function Story() {
  return (
    <section className="section-padding">
      <div className="container-fluid rest mb-100">
        <div className="col-md-12">
          <div className="text-center mb-30">
            <h1>Exploration Canvas</h1>
            <p>Take an idea or concept as far as posible for 10-15mins.</p>
          </div>
        </div>
        <Swiper
          modules={[Navigation, Pagination, Parallax]}
          //navigation
          //pagination={{ clickable: true }}
          parallax
          spaceBetween={0}
          centeredSlides={true}
          loop={true}
          className="project2-swiper"
          //style={{ overflow: "visible", maxWidth: "640px" }}
        >
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
        </Swiper>
        <Lottie
          id="slide-lottie-img1"
          animationData={SlideRightLottie}
          loop={true}
          style={{ marginTop: "-25px" }}
        />
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="cont section-padding">
              <div className="row">
                <div className="col-md-3 sm-mb30">
                  <h6>Stylescapes</h6>
                </div>
                <div className="col-lg-6 col-md-9">
                  <div className="text">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/stand/1.jpg"
                    alt="Slide 1"
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/stand/1.jpg"
                    alt="Slide 1"
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/stand/1.jpg"
                    alt="Slide 1"
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/stand/1.jpg"
                    alt="Slide 1"
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/stand/1.jpg"
                    alt="Slide 1"
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/stand/1.jpg"
                    alt="Slide 1"
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/stand/1.jpg"
                    alt="Slide 1"
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/stand/1.jpg"
                    alt="Slide 1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="cont section-padding">
              <div className="row">
                <div className="col-md-3 sm-mb30">
                  <h6>The Narrative</h6>
                </div>
                <div className="col-lg-6 col-md-9">
                  <div className="text">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="img"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img
                src="/dark/assets/imgs/works/stand/1.jpg"
                alt=""
                style={{ maxWidth: "900px" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid rest mb-100">
        <div className="col-md-12">
          <div className="text-center mt-120 mb-30">
            <h1>The Title</h1>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
            </p>
          </div>
        </div>
        <Swiper
          modules={[Navigation, Pagination, Parallax]}
          //navigation
          //pagination={{ clickable: true }}
          pagination={false}
          parallax
          spaceBetween={0}
          centeredSlides={true}
          loop={true}
          className="project2-swiper"
          style={{ overflow: "visible", maxWidth: "1920px" }}
        >
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img src="/dark/assets/imgs/works/stand/1.jpg" alt="Slide 1" />
            </div>
          </SwiperSlide>
        </Swiper>
        <Lottie
          id="slide-lottie-img1"
          animationData={SlideRightLottie}
          loop={true}
          style={{ marginTop: "-25px" }}
        />
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="cont section-padding">
              <div className="row mb-30">
                <div className="col-md-3 sm-mb30">
                  <h6>Outcome</h6>
                </div>
                <div className="col-lg-6 col-md-9">
                  <div className="text">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                      <br />
                      <br />
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 sm-mb30">
                  <h6>Next on the list</h6>
                </div>
                <div className="col-lg-6 col-md-9">
                  <div className="text">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry.
                    </p>
                    <Link
                      to="/archives"
                      className="fz-28 fw-400 stroke next-archive-link"
                    >
                      Name_of_Next_Project
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Story;
