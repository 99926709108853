"use client";
import React, { useLayoutEffect } from "react";
import { gsap } from "gsap";
import { Link } from "react-router-dom";

function ArchiveHeaderTrans() {
  useLayoutEffect(() => {
    const tl = gsap.timeline();
    tl.fromTo(".breadcrumbtop", { x: -200 }, { x: 0 }, "+=0");
    tl.fromTo(
      ".breadcrumbtop",
      { opacity: 0, translateX: -40 },
      { opacity: 1, translateX: 0 },
      "-=0"
    );

    // Cleanup function
    return () => tl.kill();
  }, []);

   console.clear();
   
  return (
    <section
      className="breadcrumbtop pagetop"
      style={{
        maxHeight: "40px",
        padding: "0",
        position: "fixed",
        top: "0",
        width: "100%",
        zIndex: "11",
      }}
    >
      <div
        className="breadcrumbtop section-padding"
        style={{
          height: "60px",
          padding: "1rem 1rem 0",
          background: "transparent",
        }}
      >
        <div
          className="container breadcrumb-container"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginLeft: "0.5rem",
          }}
        >
          <div className="text-left">
            {/*  <h1>ARCHIVES</h1> */}
            <div className="mt-0">
              {/* <a href="/">Home</a> */}
              <span className="padding-rl-10 opacity-5">|</span>
              <Link
                to="/archives"
                className="main-color"
                style={{ userSelect: "none" }}
              >
                Archives
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ArchiveHeaderTrans;
console.clear();
