
import BgRazor from './bg-razor/bg-razor';
import NavMain from './nav-main/nav-main';
import FooterCopyright from '../footer-copyright/footer-copyright';

const NavMainFrame = () => {
  return (
    <>
      <BgRazor />
      <NavMain />
      <FooterCopyright />
    </>
  );
};

export default NavMainFrame;
