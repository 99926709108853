import React from "react";

function Skillhats() {
  return (
    <div className="my-skills2">
      <div className="container">
        <div className="sec-head text-center mb-80">
          <h6 className="sub-title main-color mb-0">Tools of the trade</h6>
          <h2>
            Platforms <span className="fw-200">of choice</span>
          </h2>
          <p className="fz-14">
            Although, I am proficient in a variety of creative suites since the
            turn of the century, I have a few favorites that I use on a daily
            basis.
          </p>
        </div>
        <div className="row md-marg">
          <div className="col-lg-2 col-md-4 col-6">
            <div className="item mb-30">
              <div className="main-bg">
                {/* <div className="box-bord main-bg"> */}
                <div className="img">
                  <img src="/dark/assets/imgs/resume/icon-figma.png" alt="" />
                </div>
                {/* <span className="value">95%</span> */}
              </div>
              <h6 className="fz-16">UI / UX Design</h6>
            </div>
          </div>

          <div className="col-lg-2 col-md-4 col-6">
            <div className="item mb-30">
              <div className="main-bg">
                {/* <div className="box-bord main-bg"> */}
                <div className="img">
                  <img src="/dark/assets/imgs/resume/icon-vs.png" alt="" />
                </div>
                {/* <span className="value">95%</span> */}
              </div>
              <h6 className="fz-16">FrontEnd / BackEnd Developments</h6>
            </div>
          </div>

          <div className="col-lg-2 col-md-4 col-6">
            <div className="item mb-30">
              <div className="main-bg">
                {/* <div className="box-bord main-bg"> */}
                <div className="img">
                  <img src="/dark/assets/imgs/resume/icon-pr.png" alt="" />
                </div>
                {/* <span className="value">95%</span> */}
              </div>
              <h6 className="fz-16">Post-Production</h6>
            </div>
          </div>

          <div className="col-lg-2 col-md-4 col-6">
            <div className="item mb-30">
              <div className="main-bg">
                {/* <div className="box-bord main-bg"> */}
                <div className="img">
                  <img src="/dark/assets/imgs/resume/icon-ae.png" alt="" />
                </div>
                {/* <span className="value">95%</span> */}
              </div>
              <h6 className="fz-16">Motion-Graphics</h6>
            </div>
          </div>

          <div className="col-lg-2 col-md-4 col-6">
            <div className="item mb-30">
              <div className="main-bg">
                {/* <div className="box-bord main-bg"> */}
                <div className="img">
                  <img
                    src="/dark/assets/imgs/resume/icon-photoshop.png"
                    alt=""
                  />
                </div>
                {/* <span className="value">95%</span> */}
              </div>
              <h6 className="fz-16">Graphics Design</h6>
            </div>
          </div>

          <div className="col-lg-2 col-md-4 col-6">
            <div className="item mb-30">
              <div className="main-bg">
                {/* <div className="box-bord main-bg"> */}
                <div className="img">
                  <img
                    src="/dark/assets/imgs/resume/icon-illustrator.png"
                    alt=""
                  />
                </div>
                {/* <span className="value">95%</span> */}
              </div>
              <h6 className="fz-16">Graphics Design</h6>
            </div>
          </div>

          <div className="col-lg-2 col-md-4 col-6">
            <div className="item mb-30">
              <div className="main-bg">
                {/* <div className="box-bord main-bg"> */}
                <div className="img">
                  <img
                    src="/dark/assets/imgs/resume/icon-indesign.png"
                    alt=""
                  />
                </div>
                {/* <span className="value">95%</span> */}
              </div>
              <h6 className="fz-16">Publishing</h6>
            </div>
          </div>

          <div className="col-lg-2 col-md-4 col-6">
            <div className="item mb-30">
              <div className="main-bg">
                {/* <div className="box-bord main-bg"> */}
                <div className="img">
                  <img src="/dark/assets/imgs/resume/icon-autocad.png" alt="" />
                </div>
                {/* <span className="value">95%</span> */}
              </div>
              <h6 className="fz-16">Interior Design</h6>
            </div>
          </div>

          <div className="col-lg-2 col-md-4 col-6">
            <div className="item mb-30">
              <div className="main-bg">
                {/* <div className="box-bord main-bg"> */}
                <div className="img">
                  <img src="/dark/assets/imgs/resume/icon-revit.png" alt="" />
                </div>
                {/* <span className="value">95%</span> */}
              </div>
              <h6 className="fz-16">Interior Design</h6>
            </div>
          </div>

          <div className="col-lg-2 col-md-4 col-6">
            <div className="item mb-30">
              <div className="main-bg">
                {/* <div className="box-bord main-bg"> */}
                <div className="img">
                  <img src="/dark/assets/imgs/resume/icon-sld.png" alt="" />
                </div>
                {/* <span className="value">95%</span> */}
              </div>
              <h6 className="fz-16">Industrial Design</h6>
            </div>
          </div>

          <div className="col-lg-2 col-md-4 col-6">
            <div className="item mb-30">
              <div className="main-bg">
                {/* <div className="box-bord main-bg"> */}
                <div className="img">
                  <img src="/dark/assets/imgs/resume/icon-blender.png" alt="" />
                </div>
                {/* <span className="value">95%</span> */}
              </div>
              <h6 className="fz-16">3D Modelling</h6>
            </div>
          </div>

          <div className="col-lg-2 col-md-4 col-6">
            <div className="item mb-30">
              <div className="main-bg">
                {/* <div className="box-bord main-bg"> */}
                <div className="img">
                  <img src="/dark/assets/imgs/resume/icon-sketchup.png" alt="" />
                </div>
                {/* <span className="value">95%</span> */}
              </div>
              <h6 className="fz-16">3D Modelling</h6>
            </div>
          </div>
{/*  */}
        </div>
      </div>
    </div>
  );
}

export default Skillhats;
