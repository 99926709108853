"use client";
import React, { useLayoutEffect } from "react";
import { gsap } from "gsap";
import { Navigation, Pagination, Parallax } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import Lottie from "lottie-react";
import SlideRightLottie from "../../../animation/Slide-Lottie2.json";

function Header() {
  useLayoutEffect(() => {
    const tl = gsap.timeline();
    tl.fromTo(".header-project2", { y: 200 }, { y: 0 }, "+=0.5");
    tl.fromTo(
      ".header-project2 .container-xl",
      { opacity: 0, translateY: 40 },
      { opacity: 1, translateY: 0 },
      "-=0"
    );

    // Cleanup function
    return () => tl.kill();
  }, []);

  return (
    <section
      className="header header-project2 section-padding pb-0"
      id="topofpage"
    >
      <div className="container-xl mt-80 mb-80">
        <div className="row align-items-end">
          <div className="col-lg-6">
            <div className="full-width mb-30">
              <h6 className="mb-0 main-color">Commercial Interior Design</h6>
              <h2 className="mb-10">FTI Consulting</h2>
              <p>
                In 2018, I served as the Lead Designer for FTI Consulting
                Singapore’s interior design project, spanning 10,620 square
                feet. Tasked with developing a unique and inspiring workplace
                environment, I crafted a concept rooted in resilience and
                strength, symbolized by “The Pillars of Strength Against the
                Raging Storm,” drawing inspiration from the story of Noah's Ark.
              </p>

              <h6 className="mt-30 main-color">Awarded</h6>
              <h6 className="mt-0">Floorsize: 10,621sf</h6>
            </div>
          </div>
          <div className="col-lg-5 offset-lg-1">
            <div className="info">
              <div className="row">
                <div className="col-md-6">
                  <div className="item mb-30">
                    <span className="opacity-8 mb-5 fz-12">Category :</span>
                    <h6>Interior Design</h6>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="item mb-30">
                    <span className="opacity-8 mb-5 fz-12">Client :</span>
                    <h6>FTI Consulting, Singapore</h6>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="item mb-30">
                    <span className="opacity-8 mb-5 fz-12">Year :</span>
                    <h6>2018</h6>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="item">
                    <span className="opacity-8 mb-5 fz-12">Company:</span>
                    <h6>
                      SCA Designs | Ong & Ong Group <br />
                      <p className=" fz-12 fw-500 mt-5">- Lead Designer</p>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid rest mb-30">
        <div
          className="img img-long-container"
          style={{
            display: "flex",
            justifyContent: "center",
            background: "#e8ebee",
            backgroundImage:
              "url(/dark/assets/imgs/works/projects/interior/interior-fti/T01.webp)",
            margin: "auto",
            width: "100%",
            overflowX: "scroll",

            "@media (max-width: 1400px)": {
              minWidth: "calc(100% + 100px) !important",
              justifyContent: "flex-start !important",
              overflowX: "scroll",
            },
            "@media (max-width: 768px)": {
              minWidth: "calc(100% + 100px) !important",
              justifyContent: "flex-start !important",
            },
          }}
        >
          <img
            className="img  img-long img-maxh1"
            src="/dark/assets/imgs/works/projects/interior/interior-fti/T01.webp"
            alt="dashten"
          />
        </div>
      </div>
      <Lottie
        id="slide-lottie-img1"
        animationData={SlideRightLottie}
        loop={true}
      />

      <div className="container-fluid rest">
        <div className="col-md-12">
          <div className="text-center mt-120 mb-15">
            <p>Concept Overview</p>
          </div>
        </div>
        <Swiper
          modules={[Navigation, Pagination, Parallax]}
          //navigation
          pagination={false}
          parallax
          spaceBetween={0}
          centeredSlides={true}
          loop={true}
          className="project2-swiper"
        >
          <SwiperSlide className="slide-maxh1-40" style={{ marginRight: "0" }}>
            <div className="img img-maxh1-wrapper">
              <img
                className="slide-img-maxh1-50"
                src="/dark/assets/imgs/works/projects/interior/interior-fti/01.webp"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-maxh1-40" style={{ marginRight: "0" }}>
            <div className="img img-maxh1-wrapper">
              <img
                className="slide-img-maxh1-50"
                src="/dark/assets/imgs/works/projects/interior/interior-fti/02.webp"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide className="slide-maxh1-40" style={{ marginRight: "0" }}>
            <div className="img img-maxh1-wrapper">
              <img
                className="slide-img-maxh1-50"
                src="/dark/assets/imgs/works/projects/interior/interior-fti/03.webp"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-maxh1-40" style={{ marginRight: "0" }}>
            <div className="img img-maxh1-wrapper">
              <img
                className="slide-img-maxh1-50"
                src="/dark/assets/imgs/works/projects/interior/interior-fti/04.webp"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-maxh1-40" style={{ marginRight: "0" }}>
            <div className="img img-maxh1-wrapper">
              <img
                className="slide-img-maxh1-50"
                src="/dark/assets/imgs/works/projects/interior/interior-fti/05.webp"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
        </Swiper>
        <Lottie
          id="slide-lottie-img2"
          animationData={SlideRightLottie}
          loop={true}
          style={{ marginTop: "-25px" }}
        />
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="cont mt-60">
              <div className="row mb-0">
                <div className="col-md-3 sm-mb30">
                  <h6>Concept</h6>
                  {/* <h6>Circular Floorplate Challenges</h6> */}
                </div>
                <div className="col-lg-6 col-md-9">
                  <div className="text">
                    <p>
                      The design concept represented the company's fortitude and
                      adaptability in an ever-changing business landscape, much
                      like the Ark's pillars standing firm amid a tumultuous
                      storm. Clean lines, natural materials, and structural
                      elements conveyed strength, while subtle nautical motifs
                      infused the space with a sense of protection and forward
                      momentum.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 sm-mb30"></div>
                <div className="col-lg-6 col-md-9">
                  <div className="text"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Header;
