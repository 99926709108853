/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { Stack, Chip } from "@mui/material";

function Team() {
  const controls = useAnimation();
  const imgRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          controls.start({
            y: [100, 0],
            transition: { duration: 0.8, ease: "easeOut" },
          });
        }
      },
      {
        threshold: 0.1,
      }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, [controls]);

  return (
    <section className="team-single header pagetop" id="topofpage">
      <div className="container-fluid">
        <div className="row xlg-marg">
          <div className="col-lg-6">
            <div className="img">
              <motion.img
                id="hz-avatar-img"
                ref={imgRef}
                initial={{ y: 100 }}
                animate={controls}
                src="/dark/assets/imgs/team/t4.webp"
                alt=""
                data-speed="1.5"
                data-lag="0"
                style={{
                  mixBlendMode: "lighten",
                  filter: "saturate(0.1) brightness(1) contrast(1.15)",
                  width: "100%",
                  height: "auto",
                }}
              />
            </div>
          </div>
          <div className="col-lg-6 valign about-cont-container">
            <div className="cont about-cont">
              <h1>HADI ZAINAL</h1>
              <h6
                className="text-u ls4 opacity-8 ml-5 fw-200"
                style={{ fontSize: "16px" }}
              >
                Design Extraordinaire
              </h6>
              <Stack
                id="about-chips"
                direction="row"
                mt={4}
                ml={1}
                spacing={1}
                sx={{ scale: "0.8", transformOrigin: "left" }}
              >
                <Chip
                  label="Designs Dusted | Founder"
                  variant="outlined"
                  sx={{ color: "snow", position: "relative", left: "8px" }}
                />
                <Chip
                  label="Digital Hustlaz | Co-Founder"
                  variant="outlined"
                  sx={{ color: "snow" }}
                />
                <Chip
                  label="Real Serious Games | Snr Designer & Marketing Lead"
                  variant="outlined"
                  sx={{ color: "snow" }}
                />
              </Stack>
              <div className="text main-bg2">
                <p className="main-text">
                  As designers, we navigate unique challenges and acquire
                  diverse skills along the way. Life's unpredictability demands
                  resilience, making our design capabilities our most valuable
                  tools. We are grateful for shared knowledge, growth
                  opportunities, and humbling challenges.
                </p>
                <div className="row md-marg mt-40">
                  <div className="col-sm-6">
                    <p>
                      My design proficiency has served as my strategic asset for
                      over two decades, enabling me to navigate diverse
                      industries. Design versatility and dedication to the
                      evolution of design has empowered me to harness and
                      channel these skills to the advantage of various
                      companies.
                    </p>
                  </div>
                  <div className="col-sm-6">
                    <p>
                      Graduated from Queensland University of Technology with a
                      Bachelor of Design, majoring in Industrial design and
                      Interior design and later further studies in Masters of
                      Business Process Management, incorporating business and
                      information technology, I have aquired a sound
                      understanding of the industry and have lead projects
                      ranging from industrial design and engineering, interior
                      design, web and applications developments as well as
                      information technology driven solutions, in the last 2
                      decades.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Team;
