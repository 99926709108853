import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ButtonMenu from "../button-menu/button-menu";
import ButtonNavMainHome from "../button-nav-main-home/button-nav-main-home";
import ButtonNavMainDesj from "../button-nav-main-desj/button-nav-main-desj";
import ButtonNavMainAbout from "../button-nav-main-about/button-nav-main-about";
import ButtonNavMainServices from "../button-nav-main-services/button-nav-main-services";
import styles from "./nav-main.module.css";
import TopLogo from "../../top-logo/top-logo";
import { Link } from "react-router-dom";

const NavMain = () => {
  const location = useLocation();
  const [activeButton, setActiveButton] = useState("");

  useEffect(() => {
    setActiveButton(location.pathname);
  }, [location.pathname]);

  return (
    <>
    <TopLogo />
    <nav className={styles.navmain}>
      <div className={styles.navbottomcontainer}>
        <img
          className={styles.vectorbgIcon}
          alt="Background Vector"
          src="/dark/assets/imgs/nav/vectorbg.svg"
        />
        <div className={styles.menuwrapper}>
          <ButtonMenu />
          <Link  to="/">
          <ButtonNavMainHome isActive={activeButton === "/"} />
          </Link>
          <Link  to="/services">
            <ButtonNavMainServices isActive={activeButton === "/services"} />
          </Link>
          <Link  to="/design-journey">
            <ButtonNavMainDesj isActive={activeButton === "/design-journey"} />
          </Link>
          <Link  to="/about">
            <ButtonNavMainAbout isActive={activeButton === "/about"} />
          </Link>
        </div>
      </div>
    </nav>
    </>
  );
};

export default NavMain;
