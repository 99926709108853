// PrivateRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase";
import LoadingScreen from "../common/loader";

const PrivateRoute = ({ children }) => {
  const [user, loading, error] = useAuthState(auth);

  if (loading) {
    return <div><LoadingScreen /></div>;
  }

  if (error) {
    console.error("Error checking authentication state: ", error);
    return <div>Error</div>; // Add an error state if needed
  }

  return user ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
