"use client";
import { Helmet } from "react-helmet";
import React, { useEffect, useRef } from "react";
import ProgressScroll from "../components/default/ProgressScroll";
import HeaderFreelancer from "../components/default/HeaderFreelancer";
import Marquee2 from "../components/default/Marquee2";
import Blog from "../components/default/Blog";
import Brands from "../components/default/Brands";
import ImageScale from "../components/default/ImageScale";
import Price from "../components/default/Price";
import Testimonials from "../components/default/Testimonials";
import ServicesOffered from "../components/default/ServicesOffered";
import Footer from "../components/default/Footer";
import Lottie from "lottie-react";
import HZLogoAnimation from "../components/animation/HZ-Logo-Lottie.json";
import ServicesHeader from "../components/default/ServicesHeader";
import LoadingScreen from "../common/loader";

export default function ServicesHZ() {
  const lottieRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (lottieRef.current) {
        lottieRef.current.play();
      }
    }, 1000); // Delay the start by 1000ms (1 second)

    return () => clearTimeout(timer); // Clear the timer if the component unmounts
  }, []);

  return (
    <>
      <Helmet>
        <title>Archives | Services</title>
        <link rel="icon" href="/dark/imgs/nav/favicon.ico" />
        <link rel="shortcut icon" href="/dark/imgs/nav/favicon.ico" />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/plugins.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/style.css"
        />
      </Helmet>
      <body>
        <LoadingScreen />
        <ProgressScroll />
        <div id="smooth-wrapper">
          <div id="smooth-content">
            <main className="main-bg">
              <ServicesHeader />
              <HeaderFreelancer />
              <Marquee2 />
              <ServicesOffered />
              <Price />
              <Testimonials />
              <Brands />
              <ImageScale />
              <Blog />
            </main>
            <Footer />
            <div className="hz-lottie-container header">
              <Lottie
                lottieRef={lottieRef}
                id="hz-logo-animation"
                animationData={HZLogoAnimation}
                loop={false}
                style={{ display: "inline-block" }}
                play={false} 
              />
            </div>
          </div>
        </div>
      </body>
    </>
  );
}

console.clear();
