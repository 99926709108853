"use client";
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import projectData from "../../../../data/projectarchives/anz6623Data.json";

function Challenge() {
  const { accordionData } = projectData;

  return (
    <section className="section-padding bord-thin-bottom">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <h1 className="mb-50">
              The <br /> Design
              <br /> Process
            </h1>
          </div>
          <div className="col-lg-7">
            <div className="text">
              <h6 className="line-height-40">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </h6>
            </div>
            <div className="text mt-30">
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </div>
            <div className="accordion bord mt-50">
              {accordionData.map((item, index) => (
                <Accordion
                  key={index}
                  defaultExpanded={index === 0}
                  sx={{
                    backgroundColor: "#040404",
                    color: "white",
                    borderBottom: "1px solid #0d122e",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "yellowgreen" }} />}
                    aria-controls={`panel${index + 1}a-content`}
                    id={`panel${index + 1}a-header`}
                  >
                    <Typography variant="h6">{item.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{item.content}</Typography>
                    {item.imgSrc && (
                      <div className="img mt-30">
                        <img src={item.imgSrc} alt={`Slide ${index + 1}`} />
                      </div>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Challenge;

console.clear();
