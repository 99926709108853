import React from "react";
import { Box, Drawer, List, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Fade from "@mui/material/Fade";
import MenuItem03 from "../nav-menu-items-02/nav-menu-items-03";
import styles from "./button-menu.module.css";
import SlideUpInView from "../../../animation/SlideUpInView";

const ButtonMenu = () => {
  const [state, setState] = React.useState({ bottom: false });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
    const mainElement = document.querySelector(".main-bg");
    if (mainElement) {
      if (open) {
        mainElement.classList.add("lock-scroll");
      } else {
        mainElement.classList.remove("lock-scroll");
      }
    }
  };

  const list = (anchor) => (
    <>
      <Box id="menuDrawerContainer" className={styles.dBox}>
        <List className="dFlexList">
          {/* Pass the toggleDrawer function to MenuItem03 */}
          <MenuItem03 handleClose={toggleDrawer(anchor, false)} />
        </List>
      </Box>
    </>
  );

  return (
    <div className="dContainer">
      {["bottom"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Tooltip
            className={styles.tooltip}
            title="Main Menu"
            placement="top-start"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 200 }}
          >
            <button
              className={`${styles.buttonmenu} nav-mainmenu-button`}
              onClick={toggleDrawer(anchor, true)}
            >
              <div className={styles.buttonmenumain}>
                <img
                  className={styles.iconmenu}
                  alt=""
                  src="/dark/assets/imgs/nav/iconmenu.svg"
                />
              </div>
            </button>
          </Tooltip>

          <Drawer
            id="menuDrawerActiveBg"
            className="menuDrawerActiveBg"
            open={state[anchor]}
            anchor={anchor}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
            <SlideUpInView>
              <button
                className={styles.buttonmenuClose}
                onClick={toggleDrawer(anchor, false)}
              >
                <CloseIcon />
              </button>
            </SlideUpInView>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default ButtonMenu;
