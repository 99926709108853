/* eslint-disable react/prop-types */
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./button-nav-main-about.module.css";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";

const ButtonNavMainAbout = ({ isActive }) => {
  const navigate = useNavigate();

  const onButtonNavMainClick = useCallback(() => {
    navigate("/about");
  }, [navigate]);

  return (
    <Tooltip
      className={styles.tooltip}
      title="About"
      placement="top-start"
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 200 }}
      id="tooltip-mainmenu"
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [34, -8],
              },
            },
          ],
        },
      }}
    >
      <button
        className={`${styles.buttonNavmain} ${isActive ? styles.active : ""}`}
        id="AboutButton"
        onClick={onButtonNavMainClick}
      >
        <img
          className={styles.bubblesubtract01Icon}
          alt="svg"
          id="bubble-subract-about"
          src="/dark/assets/imgs/nav/bubblesubtract01.svg"
        />
        <img
          className={styles.iconabout}
          alt="icon"
          id="icon-about"
          src="/dark/assets/imgs/nav/iconabout.svg"
        />
      </button>
    </Tooltip>
  );
};

export default ButtonNavMainAbout;
