"use client";
import React, { useEffect } from "react";
import { gsap } from "gsap";

function LoadingScreen() {
  useEffect(() => {
    const interval = setInterval(() => {
      if (typeof gsap !== "undefined") {
        clearInterval(interval);

        const tl = gsap.timeline();

        // Fade out and hide the loader
        tl.to(".loader-wrap", {
          delay: 0,
          y: 0,
          opacity: 0,
        })
          .to(".loader-wrap", {
            y: 0,
          })
          .to(".loader-wrap", {
            zIndex: -1,
            display: "none",
          });

        // Animations for elements after loading
        tl.from(
          "header",
          {
            y: 200,
          },
          "-=0"
        ).from(
          "header #topofpage",
          {
            y: 40,
            opacity: 0,
            delay: 0,
          },
          "-=0"
        );
      }
    }, 0);
  }, []);

  return (
    <div className="loader-wrap">{/* Your loading screen content here */}</div>
  );
}

export default LoadingScreen;
