import { useRef, useEffect, useState } from "react";
import { motion } from "framer-motion";
import PropTypes from 'prop-types';


const SlideUpInView = ({ children, delay }) => {
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  // Use IntersectionObserver to detect when the component is in view
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0,
      }
    );

    observer.observe(ref.current);

    // Cleanup function
    return () => observer.disconnect();
  }, []);

  // Check if the delay value is a valid number
  const animationDelay = typeof delay === "number" ? delay : 0;

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: '80px' }}
      animate={{
        opacity: isVisible ? 1 : 1,
        y: isVisible ? 1 : 0,
      }}
      exit={{ opacity: 1, y: '-80px' }}
      transition={{
        duration: 0.3,
        delay: animationDelay,
      }}
    >
      {children}
    </motion.div>
  );
};

SlideUpInView.propTypes = {
  children: PropTypes.node.isRequired,
  delay: PropTypes.number,
};


export default SlideUpInView;

console.clear();
