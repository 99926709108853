import React, { useLayoutEffect } from "react";
import { gsap } from "gsap";

function Marquee2() {
  useLayoutEffect(() => {
    const tl = gsap.timeline({delay: 0}); // Add a delay of 1 second to the start of the timeline
    tl.fromTo(".marqi", { y: -500 }, { y: 0 }, "+=0");
    tl.fromTo(
      ".marqi",
      { opacity: 0, translateY: -50 },
      { opacity: 1, translateY: 0 },
      "-=0"
    );

    // Cleanup function
    return () => tl.kill();
  }, []);

  console.clear();

  
  return (
    <section className="marquee-skew marqi">
      <div className="container-fluid rest">
        <div className="row">
          <div className="col-12">
            <div className="main-marq lrg">
              <div className="slide-har st1">
                <div className="box non-strok">
                  <div className="item">
                    <h4 className="d-flex align-items-center">
                      <span>Design as a Service</span>
                      <span className="fz-50 ml-50 stroke icon">*</span>
                    </h4>
                  </div>
                  <div className="item">
                    <h4 className="d-flex align-items-center">
                      <span>Get Design Assets Dusted!</span>{" "}
                      <span className="fz-50 ml-50 stroke icon">*</span>
                    </h4>
                  </div>
                  <div className="item">
                    <h4 className="d-flex align-items-center">
                      <span>Web App Assets</span>{" "}
                      <span className="fz-50 ml-50 stroke icon">*</span>
                    </h4>
                  </div>
                  <div className="item">
                    <h4 className="d-flex align-items-center">
                      <span>Marketing Content</span>{" "}
                      <span className="fz-50 ml-50 stroke icon">*</span>
                    </h4>
                  </div>
                  <div className="item">
                    <h4 className="d-flex align-items-center">
                      <span>Motion-Graphics</span>{" "}
                      <span className="fz-50 ml-50 stroke icon">*</span>
                    </h4>
                  </div>
                  <div className="item">
                    <h4 className="d-flex align-items-center">
                      <span>Post-Production</span>{" "}
                      <span className="fz-50 ml-50 stroke icon">*</span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Marquee2;
