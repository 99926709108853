// App.js
//"use client";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import "./App.css";
import NavMainFrame from "./components/default/nav-main-frame/nav-main-frame";
import TransToTop from "./common/TransToTop";
//Public Pages
import Home from "./pages";
import AboutHz from "./pages/about";
import DesignJourney from "./pages/design-journey";
import ServicesHZ from "./pages/services";
import Login from "./pages/login";
import ForgotPassword from "./pages/forgot-password";
import Register from "./pages/register";
//Archives
import Archives from "./pages/Archives";
import ProjectBrandRsg from "./pages/project-branding-rsg";
import ProjectWebRsg from "./pages/project-website-rsg";
import Blogs from "./pages/Blogs";
import ProjectAnz6623 from "./pages/project-industrial-anza";
//404
import NotFoundPage from "./pages/NotFoundPage";
import ProjectTemplate from "./pages/project-template";
import ProjectWebRsgDashten from "./pages/project-website-dashten";
import ProjectInteriorFti from "./pages/project-interior-fti";
import ProjectInteriorMas from "./pages/project-interior-mas";







function App() {
  useEffect(() => {
    document.body.style.overflow = "auto"; // or 'scroll'
  }, []);
  useEffect(() => {
    const initializeLocomotiveScroll = async () => {
      if (typeof window !== "undefined") {
        const LocomotiveScroll = (await import("locomotive-scroll")).default;

        const locomotiveScrollInstance = new LocomotiveScroll({
          el: document.querySelector(".lenis.lenis-smooth"),
          smooth: true,
        });

        document.querySelector(".lenis.lenis-smooth").style.scrollBehavior =
          "auto";
        document.querySelector(".lenis.lenis-smooth").style.height = "auto";

        return () => {
          locomotiveScrollInstance.destroy();
          clearInterval();
        };
      }
    };
    initializeLocomotiveScroll();
  }, []);

  return (
    <Router basename="/">
      <NavMainFrame />
      <TransToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutHz />} />
          <Route path="/design-journey" element={<DesignJourney />} />
          <Route path="/services" element={<ServicesHZ />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/404" element={<NotFoundPage />} />
          <Route
            path="/archives"
            element={
              <PrivateRoute>
                <Archives />
              </PrivateRoute>
            }
          />
          <Route
            path="/archives/branding/rsg"
            element={
              <PrivateRoute>
                <ProjectBrandRsg />
              </PrivateRoute>
            }
          />
          <Route
            path="/archives/websites/rsg"
            element={
              <PrivateRoute>
                <ProjectWebRsg />
              </PrivateRoute>
            }
          />
          <Route
            path="/archives/websites/dashten"
            element={
              <PrivateRoute>
                <ProjectWebRsgDashten />
              </PrivateRoute>
            }
          />
          <Route
            path="/archives/industrial-design/anz6623"
            element={
              <PrivateRoute>
                <ProjectAnz6623 />
              </PrivateRoute>
            }
          />
          <Route
            path="/archives/interior-design/fti-consulting-singapore"
            element={
              <PrivateRoute>
                <ProjectInteriorFti />
              </PrivateRoute>
            }
          />
          <Route
            path="/archives/interior-design/mas-singapore"
            element={
              <PrivateRoute>
                <ProjectInteriorMas />
              </PrivateRoute>
            }
          />
          <Route
            path="/archives/project-template"
            element={
              <PrivateRoute>
                <ProjectTemplate />
              </PrivateRoute>
            }
          />
          <Route
            path="/blogs"
            element={
              <PrivateRoute>
                <Blogs />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </TransToTop>
    </Router>
  );
}

export default App;
console.clear();
