import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Navigation, Pagination, Parallax } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import Lottie from "lottie-react";
import SlideRightLottie from "../../../animation/Slide-Lottie2.json";

function Challenge() {
  return (
    <section className="section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <h1 className="mb-50">
              The
              <br /> Process
            </h1>
          </div>
          <div className="col-lg-7">
            <div className="text">
              <h6 className="line-height-40">A few words about the project.</h6>
            </div>
            <div className="text mt-30">
              <p>Project description.</p>
            </div>
            <div className="accordion bord mt-50">
              <Accordion
                defaultExpanded
                sx={{
                  backgroundColor: "#040404",
                  color: "white",
                  borderBottom: "1px solid #0d122e",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "yellowgreen" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">Stage 1</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>Stage 1 description.</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={{
                  backgroundColor: "#040404",
                  color: "white",
                  borderBottom: "1px solid #0d122e",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "yellowgreen" }} />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography variant="h6">Stage 2</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>Stage 2 description.</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={{
                  backgroundColor: "#040404",
                  color: "white",
                  borderBottom: "1px solid #0d122e",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "yellowgreen" }} />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography variant="h6">Stage 3</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>Stage 3 description.</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={{
                  backgroundColor: "#040404",
                  color: "white",
                  borderBottom: "1px solid #0d122e",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "yellowgreen" }} />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography variant="h6">Stage 4</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>Stage 4 description.</Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </div>

      <div className="section-padding">
        <Swiper
          modules={[Navigation, Pagination, Parallax]}
          //navigation
          pagination={false}
          parallax
          spaceBetween={0}
          centeredSlides={true}
          loop={true}
          className="project2-swiper"
        >
          <SwiperSlide className="slide-maxh1-40" style={{ marginRight: "0" }}>
            <div className="img img-maxh1-wrapper">
              <img
                className="slide-img-maxh1-50"
                src="/dark/assets/imgs/works/projects/interior/interior-mas/14.webp"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-maxh1-40" style={{ marginRight: "0" }}>
            <div className="img img-maxh1-wrapper">
              <img
                className="slide-img-maxh1-50"
                src="/dark/assets/imgs/works/projects/interior/interior-mas/15.webp"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-maxh1-40" style={{ marginRight: "0" }}>
            <div className="img img-maxh1-wrapper">
              <img
                className="slide-img-maxh1-50"
                src="/dark/assets/imgs/works/projects/interior/interior-mas/16.webp"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-maxh1-40" style={{ marginRight: "0" }}>
            <div className="img img-maxh1-wrapper">
              <img
                className="slide-img-maxh1-50"
                src="/dark/assets/imgs/works/projects/interior/interior-mas/17.webp"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-maxh1-40" style={{ marginRight: "0" }}>
            <div className="img img-maxh1-wrapper">
              <img
                className="slide-img-maxh1-50"
                src="/dark/assets/imgs/works/projects/interior/interior-mas/18.webp"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-maxh1-40" style={{ marginRight: "0" }}>
            <div className="img img-maxh1-wrapper">
              <img
                className="slide-img-maxh1-50"
                src="/dark/assets/imgs/works/projects/interior/interior-mas/19.webp"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-maxh1-40" style={{ marginRight: "0" }}>
            <div className="img img-maxh1-wrapper">
              <img
                className="slide-img-maxh1-50"
                src="/dark/assets/imgs/works/projects/interior/interior-mas/20.webp"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-maxh1-40" style={{ marginRight: "0" }}>
            <div className="img img-maxh1-wrapper">
              <img
                className="slide-img-maxh1-50"
                src="/dark/assets/imgs/works/projects/interior/interior-mas/21.webp"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-maxh1-40" style={{ marginRight: "0" }}>
            <div className="img img-maxh1-wrapper">
              <img
                className="slide-img-maxh1-50"
                src="/dark/assets/imgs/works/projects/interior/interior-mas/22.webp"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
        </Swiper>
        <Lottie
          id="slide-lottie-img2"
          animationData={SlideRightLottie}
          loop={true}
          style={{ marginTop: "-25px" }}
        />
      </div>

    </section>
  );
}

export default Challenge;
