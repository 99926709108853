import React from 'react';
import { Link } from 'react-router-dom';

function Blog() {
  return (
    <section className="blog-crev section-padding">
      <div className="container">
        <div className="sec-head mb-80">
          <h6 className="sub-title main-color mb-25">Blogs</h6>
          <div className="bord pt-25 bord-thin-top d-flex align-items-center">
            <h2 className="fw-600">
              Shared <span className="fw-200">Notes</span>
            </h2>
            <div className="ml-auto">
              <Link to="/archives" className="go-more">
                <span className="text">View all entries</span>
                <span className="icon ti-arrow-top-right"></span>
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <div className="item sub-bg1 md-mb50">
              <div className="img">
                <img src="/dark/assets/imgs/blog/c1.jpg" alt="" />
                <div className="tag sub-bg1">
                  <span>Web Tech</span>
                  <div className="shap-right-bottom">
                    <svg
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-11 h-11"
                    >
                      <path
                        d="M11 1.54972e-06L0 0L2.38419e-07 11C1.65973e-07 4.92487 4.92487 1.62217e-06 11 1.54972e-06Z"
                        fill="#0d0f1d"
                      ></path>
                    </svg>
                  </div>
                  <div className="shap-left-bottom">
                    <svg
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-11 h-11"
                    >
                      <path
                        d="M11 1.54972e-06L0 0L2.38419e-07 11C1.65973e-07 4.92487 4.92487 1.62217e-06 11 1.54972e-06Z"
                        fill="#0d0f1d"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="cont">
                <div className="date sub-title mb-10 opacity-7">
                  <Link className="fz-12" to="/archives">
                    Ecommerce
                  </Link>
                </div>
                <h6>
                  <Link to="/archives">
                    How much does it cost to build an Ecommerce website?
                  </Link>
                </h6>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="item sub-bg1 md-mb50">
              <div className="img">
                <img src="/dark/assets/imgs/blog/c2.jpg" alt="" />
                <div className="tag sub-bg1">
                  <span>Web Tech</span>
                  <div className="shap-right-bottom">
                    <svg
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-11 h-11"
                    >
                      <path
                        d="M11 1.54972e-06L0 0L2.38419e-07 11C1.65973e-07 4.92487 4.92487 1.62217e-06 11 1.54972e-06Z"
                        fill="#0d0f1d"
                      ></path>
                    </svg>
                  </div>
                  <div className="shap-left-bottom">
                    <svg
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-11 h-11"
                    >
                      <path
                        d="M11 1.54972e-06L0 0L2.38419e-07 11C1.65973e-07 4.92487 4.92487 1.62217e-06 11 1.54972e-06Z"
                        fill="#0d0f1d"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="cont">
                <div className="date sub-title mb-10 opacity-7">
                  <Link className="fz-12" to="/archives">
                    Wordpress sucks!
                  </Link>
                </div>
                <h6>
                  <Link to="/archives">
                    Why I stop using Wordpress and started coding
                  </Link>
                </h6>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="item sub-bg1">
              <div className="img">
                <img src="/dark/assets/imgs/blog/c3.jpg" alt="" />
                <div className="tag sub-bg1">
                  <span>Branding</span>
                  <div className="shap-right-bottom">
                    <svg
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-11 h-11"
                    >
                      <path
                        d="M11 1.54972e-06L0 0L2.38419e-07 11C1.65973e-07 4.92487 4.92487 1.62217e-06 11 1.54972e-06Z"
                        fill="#0d0f1d"
                      ></path>
                    </svg>
                  </div>
                  <div className="shap-left-bottom">
                    <svg
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-11 h-11"
                    >
                      <path
                        d="M11 1.54972e-06L0 0L2.38419e-07 11C1.65973e-07 4.92487 4.92487 1.62217e-06 11 1.54972e-06Z"
                        fill="#0d0f1d"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="cont">
                <div className="date sub-title mb-10 opacity-7">
                  <Link className="fz-12" to="/archives">
                    Branding
                  </Link>
                </div>
                <h6>
                  <Link to="/archives">
                    My Branding Process from Start to Finish
                  </Link>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Blog;
