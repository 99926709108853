"use client";
import React, { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import { signOut } from "firebase/auth";
import { Link } from "react-router-dom";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Footer = () => {
  const [user] = useAuthState(auth);

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  /* gsap parallax */
      gsap.set(".footer-container", { yPercent: -50 });
      const uncover = gsap.timeline({ paused: true });
      uncover.to(".footer-container", { yPercent: 0, ease: "none" });

      ScrollTrigger.create({
        trigger: "main",
        start: "bottom bottom",
        end: "+=50%",
        animation: uncover,
        scrub: true,
      });
      console.clear();

      /* motion text */
      const controls = useAnimation();
      const [ref, inView] = useInView({ triggerOnce: false });

      useEffect(() => {
        if (inView) {
          controls.start("visible");
        } else {
          controls.start("hidden");
        }
      }, [controls, inView]);

      const variants = {
        hidden: {
          opacity: 0,
          y: 50,
          z: -50,
          rotateX: -45,
          scale: 0.95,
          transition: {
            duration: 0.8,
            delay: 0,
          },
        },
        visible: {
          opacity: 1,
          y: 0,
          z: 0,
          rotateX: 0,
          scale: 1,
          transition: {
            duration: 0.3,
          },
        },
      };



  return (
    <footer className="modern-footer footer pt-80">
      <div className="footer-container" id="footer-container">
        <div className="container ontop">
          <div className="row pb-30 bord-thin-bottom">
            <div className="col-lg-5">
              <div className="logo icon-img-120" id="footer-logo-hz">
                <img src="/dark/assets/imgs/logo-light.png" alt="" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="social-media d-flex justify-content-end">
                <ul className="rest d-flex align-items-center fz-14">
                  <li className="hover-this cursor-pointer ml-50">
                    <Link to="#0" className="hover-anim">
                      <i className="fab fa-linkedin-in mr-10"></i> LinkedIn
                    </Link>
                  </li>
                  <li className="hover-this cursor-pointer ml-50">
                    <Link to="#0" className="hover-anim">
                      <i className="fab fa-pinterest mr-10"></i> Pinterst
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row pt-80">
            <div className="col-lg-7">
              <div className="call-action">
                <h2
                  className="fw-600 d-rotate"
                  ref={ref}
                  initial="hidden"
                  animate={controls}
                  variants={variants}
                >
                  <span className="hz-rotate-text" variants={variants}>
                    <span className="fw-200">Have a project in mind?</span>
                  </span>
                </h2>
                <motion.h2
                  className="fw-600 d-rotate"
                  ref={ref}
                  initial="hidden"
                  animate={controls}
                  variants={variants}
                >
                  <motion.span className="hz-rotate-text" variants={variants}>
                    <span className="hz-sideup-text">
                      <span className="up-text underline">
                        <a
                          href="mailto:hadizainal@designsdusted.com"
                          className="main-color"
                        >
                          Let’s get it Dusted.
                        </a>
                      </span>
                    </span>
                  </motion.span>
                </motion.h2>
                <div className="info mt-80 d-flex align-items-center">
                  <div>
                    <a
                      href="mailto:hadizainal@designsdusted.com"
                      className="butn butn-md butn-bord-thin radius-30"
                    >
                      <span className="text">Get-In-Touch</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <div className="col-lg-4 offset-lg-1 bord-left">
              <div className="column">
                <h6 className="sub-title mb-30">Useful Links</h6>
                <div className="row">
                  <div className="col-6">
                    <ul className="rest fz-14">
                      <li className="mb-15">
                        <Link to="/">Overview</Link>
                      </li>
                      <li className="mb-15">
                        <Link to="/services">Services</Link>
                      </li>
                      <li className="mb-15">
                        <Link to="/about">About</Link>
                      </li>
                      <li>
                        <Link to="/design-journey">Design Journey</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-6">
                    <ul className="rest fz-14">
                      <li className="mb-15">
                        <Link to="/archives">Archives</Link>
                      </li>
                      <li className="mb-15">
                        <Link to="/blogs">Journals</Link>
                      </li>
                      <li className="mb-15">
                        <Link to="/">Privacy Policy</Link>
                      </li>
                      {user ? (
                        <li>
                          <button
                            onClick={handleLogout}
                            style={{
                              color: "red",
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                              padding: "0",
                            }}
                          >
                            Logout
                          </button>
                        </li>
                      ) : (
                        <li>
                          <Link to="/login">Login</Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>

              {/*  */}
              {/* <div className="subscribe-minimal mt-50">
                <form action="contact.php">
                  <div className="form-group rest">
                    <input
                      type="email"
                      placeholder="Type Your Email"
                      id="email"
                      autoComplete="off"
                    />
                    <button type="submit">
                      <i className="ti-arrow-top-right"></i>
                    </button>
                  </div>
                </form>
              </div> */}
            </div>
          </div>
          <div className="text-center pt-30 pb-30 mt-80">
            <p className="fz-14">
              <span className="underline main-color"></span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
