import React, { useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";

function DesignJourHead() {
  const controls = useAnimation();
  const designRef = useRef(null);
  const journeyRef = useRef(null);

  useEffect(() => {
    const observerCallback = ([entry]) => {
      if (entry.isIntersecting) {
        controls.start({
          y: [100, 0],
          opacity: [1, 1],
          animationDelay: 0,
          transition: { duration: 0.8, ease: "easeInOut" },
        });
      }
    };

    const observerOptions = {
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    const designElement = designRef.current;
    const journeyElement = journeyRef.current;

    if (designElement) observer.observe(designElement);
    if (journeyElement) observer.observe(journeyElement);

    return () => {
      if (designElement) observer.unobserve(designElement);
      if (journeyElement) observer.unobserve(journeyElement);
    };
  }, [controls]);

  return (
    <section
      className="header pagetop work-asymetic section-padding"
      id="topofpage"
    >
      {/* <section
      className="header pagetop work-asymetic section-padding"
      id="designjour-headsect"
    > */}
      <div className="container-xxl container mt-100">
        <div className="caption">
          <div className="lg-text fw-300">
            <div className="text"></div>
            <motion.div className="text" ref={designRef} animate={controls}>
              Design
            </motion.div>
            <motion.div className="text" ref={journeyRef} animate={controls}>
              Journey
            </motion.div>
          </div>
          <motion.p className="text" ref={journeyRef} animate={controls}>
            When design skills became the tool to survive.
          </motion.p>
        </div>
      </div>
    </section>
  );
}

export default DesignJourHead;
