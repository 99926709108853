"use client";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import projects from "../../data/Landing/footerportfolio.json";

function Portfolio() {
  // Function to load background images
  function loadBackgroundImages() {
    const backgroundImages = document.querySelectorAll("[data-background]");

    backgroundImages.forEach((element) => {
      const image = element.dataset.background;
      element.style.backgroundImage = `url('${image}')`;
    });
  }

  // Function to load solid background colors
  function loadSolidColors() {
    const solidColors = document.querySelectorAll("[data-solid-color]");

    solidColors.forEach((element) => {
      const color = element.dataset.solidColor;
      element.style.backgroundColor = color;
    });
  }

  // Function to initialize interactive elements
  function initializeInteractions() {
    // Tab interactions
    document
      .querySelectorAll(".inter-fixed-text .links-img .img")
      .forEach((img) => {
        img.addEventListener("mouseenter", () => {
          const tabId = img.dataset.tab;
          document
            .querySelectorAll(".links-img .img")
            .forEach((el) => el.classList.remove("current"));
          img.classList.add("current");
          document
            .querySelectorAll(".links-text li")
            .forEach((el) => el.classList.remove("current"));
          document.getElementById(tabId).classList.add("current");
        });

        img.addEventListener("mouseleave", () => {
          document
            .querySelectorAll(".links-text li")
            .forEach((el) => el.classList.remove("current"));
          document
            .querySelectorAll(".links-img .img")
            .forEach((el) => el.classList.remove("current"));
        });
      });

    // Tab clicks
    document.querySelectorAll("#tabs .tab-links .item-link").forEach((link) => {
      link.addEventListener("click", () => {
        const tabId = link.dataset.tab;
        document
          .querySelectorAll("#tabs .tab-links .item-link")
          .forEach((el) => el.classList.remove("current"));
        link.classList.add("current");
        document
          .querySelectorAll(".tab-content")
          .forEach((el) => (el.style.display = "none"));
        document.getElementById(tabId).style.display = "block";
      });
    });

    // Tab fade clicks
    document
      .querySelectorAll("#tabs-fade .tab-links .item-link")
      .forEach((link) => {
        link.addEventListener("click", () => {
          const tabId = link.dataset.tab;
          document
            .querySelectorAll("#tabs-fade .tab-links .item-link")
            .forEach((el) => el.classList.remove("current"));
          link.classList.add("current");
          document
            .querySelectorAll(".tab-content")
            .forEach((el) => (el.style.display = "none"));
          document.getElementById(tabId).style.display = "block";
        });
      });

    // Portfolio tab interactions
    document.querySelectorAll(".portfolio-tab .cluom").forEach((cluom) => {
      cluom.addEventListener("mouseenter", () => {
        const tabId = cluom.dataset.tab;
        document
          .querySelectorAll(".portfolio-tab .cluom")
          .forEach((el) => el.classList.remove("current"));
        cluom.classList.add("current");
        document
          .querySelectorAll(".portfolio-tab .glry-img .tab-img")
          .forEach((el) => el.classList.remove("current"));
        document.getElementById(tabId).classList.add("current");
      });
    });
  }

  // useEffect to load images and initialize interactions on mount
  useEffect(() => {
    loadBackgroundImages();
    loadSolidColors();
    initializeInteractions();
  }, []);

  return (
    <section className="portfolio-tab bord-thin-bottom">
      <div className="container-fluid rest">
        <div className="sec-head pb-50 bord-thin-bottom">
          <div className="container">
            <h6 className="sub-title main-color mb-25">Project Archive</h6>
          </div>
          <div className="bord pt-25 bord-thin-top">
            <div className="container">
              <div className="d-flex align-items-center">
                <h2 className="fw-600">
                  Featured <span className="fw-200">Projects</span>
                </h2>
                <div className="ml-auto">
                  <Link to="/" className="go-more">
                    <span className="text">View all Projects</span>
                    <span className="icon ti-arrow-top-right"></span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 rest">
            <div className="glry-img">
              {projects.map((project) =>
                project.type === "video" ? (
                  <video
                    key={project.id}
                    id={project.id}
                    className={`bg-img tab-img ${project.id === "tab-1" ? "current" : ""}`}
                    src={project.src}
                    autoPlay
                    loop
                    muted
                    width="100%"
                  />
                ) : (
                  <div
                    key={project.id}
                    id={project.id}
                    className={`bg-img tab-img ${project.id === "tab-1" ? "current" : ""}`}
                    data-background={project.src}
                  ></div>
                )
              )}
            </div>
          </div>
          <div className="col-lg-7 rest content">
            {projects.map((project) => (
              <div key={project.id} className={`cluom ${project.id === "tab-1" ? "current" : ""}`} data-tab={project.id}>
                <div className="info">
                  <h6 className="sub-title opacity-7">{project.subtitle}</h6>
                  <h4>{project.title}</h4>
                </div>
                <div className="img">
                  {project.type === "video" ? (
                    <video
                      id={project.id}
                      className={`bg-img tab-img ${project.id === "tab-1" ? "current" : ""}`}
                      src={project.src}
                      autoPlay
                      loop
                      muted
                      width="100%"
                    />
                  ) : (
                    <img src={project.src} alt={project.title} />
                  )}
                </div>
                <div className="more text-u ls1 fz-12 link-text">
                  <Link to={project.link}>
                    View Project <i className="ml-15 ti-arrow-top-right"></i>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Portfolio;
