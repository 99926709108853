"use client";
import React, { useLayoutEffect } from "react";
import { gsap } from "gsap";
import { Navigation, Pagination, Parallax } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import Lottie from "lottie-react";
import SlideRightLottie from "../../../animation/Slide-Lottie2.json";

function Header() {
  useLayoutEffect(() => {
    const tl = gsap.timeline();
    tl.fromTo(".header", { y: 200 }, { y: 0 }, "+=0.5");
    tl.fromTo(
      ".header .container-xl",
      { opacity: 0, translateY: 40 },
      { opacity: 1, translateY: 0 },
      "-=0"
    );

    // Cleanup function
    return () => tl.kill();
  }, []);

  return (
    <div className="header header-project2 section-padding pb-0 pagetop">
      <div className="container-xl mt-80 mb-80">
        <div className="row align-items-end">
          <div className="col-lg-6">
            <div className="full-width mb-30">
              <h6 className="mb-0 main-color">Re-Branding</h6>
              <h1 className="mb-10">Real Serious Games</h1>
              <p>
                In April 2020, Real Serious Games was looking to rebrand their
                coporate identity. The goal was to create a new brand that was
                more modern and appealing.
              </p>
              <br />
              <p>
                I submitted a proposal to Real Serious Games and was awarded the
                contract to rebrand their company. The project was a success and
                the new brand was well received by the client and their
                customers. The new brand has helped Real Serious Games to stand
                out in the market and attract new customers.
              </p>
            </div>
          </div>
          <div className="col-lg-5 offset-lg-1">
            <div className="info">
              <div className="row">
                <div className="col-md-6">
                  <div className="item mb-30">
                    <span className="opacity-8 mb-5 fz-12">Category :</span>
                    <h6>Branding & Visual-Comms</h6>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="item mb-30">
                    <span className="opacity-8 mb-5 fz-12">Client :</span>
                    <h6>Real Serious Games</h6>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="item mb-30">
                    <span className="opacity-8 mb-5 fz-12">Start Date :</span>
                    <h6>April 2020</h6>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="item">
                    <span className="opacity-8 mb-5 fz-12">Company :</span>
                    <h6>
                      Digital Hustlaz
                      <br />
                      <p className=" fz-12 fw-500">- Creative Chief</p>
                    </h6>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="item">
                    <span className="mb-5">Deliverables:</span>
                    <p>
                      Branding Workshops (Brand Strategy, Brand Identity,
                      Brand), Brand Guide Documentation, Ms Office Templates
                      (Word, Powerpoint, Excel, Outlook)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid img-long-container rest mb-30">
        <div
          className="img img-long"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/dark/assets/imgs/works/projects/brand-rsg/hero01.png"
            alt="hero img"
            style={{ maxWidth: "1920px" }}
          />
        </div>
      </div>
      <Lottie
        id="slide-lottie-img1"
        animationData={SlideRightLottie}
        loop={true}
      />

      <div className="container-fluid rest">
        <div className="col-md-12">
          <div className="text-center mt-120 mb-30 sm-text-left">
            <p>
              6 - 8 weeks process: Brand Strategy, Brand Identity, Brand
              Positioning, Brand Architypes, Final Guide Documentation
            </p>
          </div>
        </div>
        <Swiper
          modules={[Navigation, Pagination, Parallax]}
          //navigation
          //pagination={{ clickable: true }}
          pagination={false}
          parallax
          spaceBetween={0}
          centeredSlides={true}
          loop={true}
          className="project2-swiper"
          //style={{ overflow: "visible", maxWidth: "50%" }}
        >
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/01.png"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/02.png"
                alt="Slide 2"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/03.png"
                alt="Slide 3"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ marginRight: "0" }}>
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/brand-rsg/04.png"
                alt="Slide 4"
              />
            </div>
          </SwiperSlide>
        </Swiper>
        <Lottie
          id="slide-lottie-img2"
          animationData={SlideRightLottie}
          loop={true}
          style={{ marginTop: "-25px" }}
        />
      </div>
    </div>
  );
}

export default Header;
