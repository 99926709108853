// Login.js
"use client";
import { Helmet } from "react-helmet";
import React, { useState } from "react";
import { auth } from "../components/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Box,
  Typography,
  Stack,
  Divider,
} from "@mui/material";
import Lottie from "lottie-react";
import HZLogoAnimation from "../components/animation/HZ-Logo-Lottie.json";


const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/archives"); // Redirect to home or desired route
    } catch (error) {
      console.error("Error signing in: ", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Archives | Login</title>
        <link rel="icon" href="/dark/imgs/nav/favicon.ico" />
        <link rel="shortcut icon" href="/dark/imgs/nav/favicon.ico" />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/plugins.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/style.css"
        />
      </Helmet>
      <body>
        <main className="main-bg" id="topofpage">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="100vh"
            bgcolor="#14172B"
            color="snow"
          >
            <div
              className="login-container"
              style={{
                position: "relative",
                background: "rgb(21 23 44 / 10%)",
                padding: "1rem",
                width: "420px",
                borderRadius: "8px",
                backdropFilter: "blur(10px)",
                borderTop: "1.5px outset #fdfdfd3d",
                borderBottom: "2px outset #fdfdfd3d",
                boxShadow: "0px 1px 20px #00000045",
                zIndex: 2,
              }}
            >
              <form
                onSubmit={handleSubmit}
                className="login-form-container"
                sx={{ padding: "1rem" }}
              >
                <Box mt={3} sx={{ padding: "1rem" }}>
                  <Divider
                    sx={{
                      "&:before": {
                        borderTop: "thin solid #fdfdfd3d",
                      },
                      "&:after": {
                        borderTop: "thin solid #fdfdfd3d",
                      },
                    }}
                  >
                    <Typography
                      component="span"
                      color="red"
                      variant="h5"
                      fontWeight="400"
                      lineHeight={1.2}
                      position="relative"
                      px={2}
                      style={{ fontSize: "24px" }} // Set the font size to 12px
                    >
                      Restricted Access
                    </Typography>
                  </Divider>
                </Box>
                <Stack>
                  <Box mt={3}>
                    <TextField
                      id="email"
                      label="Email Address"
                      autoComplete="off"
                      variant="standard"
                      fullWidth
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email Address"
                      InputProps={{
                        style: { color: "snow" },
                        classes: {
                          underline: {
                            "&:before": {
                              borderBottomColor: "#040404",
                            },
                            "&:after": {
                              borderBottomColor: "#040404",
                            },
                            "&:hover:before": {
                              borderBottomColor: "#040404",
                            },
                          },
                        },
                      }}
                      InputLabelProps={{ style: { color: "snow" } }}
                      sx={{
                        "&:before": {
                          borderBottom: "thin solid #040404",
                        },
                        "&:after": {
                          borderBottom: "thin solid #040404",
                        },
                      }}
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      id="password"
                      type="password"
                      label="Password"
                      autoComplete="off"
                      fullWidth
                      variant="standard"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password"
                      InputProps={{
                        style: { color: "snow" },
                        classes: {
                          underline: {
                            "&:before": {
                              borderBottomColor: "#040404",
                            },
                            "&:after": {
                              borderBottomColor: "#040404",
                            },
                            "&:hover:before": {
                              borderBottomColor: "#040404",
                            },
                          },
                        },
                      }}
                      InputLabelProps={{ style: { color: "snow" } }}
                      sx={{
                        "&:before": {
                          borderBottom: "thin solid #040404",
                        },
                        "&:after": {
                          borderBottom: "thin solid #040404",
                        },
                      }}
                    />
                  </Box>
                </Stack>
                <Stack
                  mt={1}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" justifyContent="space-between">
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="remember"
                          color="primary"
                          sx={{ color: "snow" }}
                        />
                      }
                      label="Remember this Device"
                      sx={{
                        color: "snow",
                        "& .MuiTypography-root": {
                          fontSize: "14px",
                        },
                        marginTop: "0px",
                      }}
                    />
                  </Box>

                  <Box display="flex" justifyContent="space-between">
                    <a
                      className="link-text"
                      href="/forgot-password"
                      variant="body2"
                      color="#eee"
                      style={{ color: "snow", fontSize: "12px" }}
                    >
                      Forgot Password?
                    </a>
                  </Box>
                </Stack>
                <Stack mt={2} justifyContent="space-between">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#000",
                      color: "snow",
                      border: "1.5px outset yellowgreen",
                    }}
                  >
                    Sign In
                  </Button>
                  <Box mt={2} textAlign="center">
                    <a
                      className="link-text"
                      href="/register"
                      variant="body"
                      color="#eee"
                      style={{ textDecoration: "none", color: "snow" }}
                    >
                      Request Access
                    </a>
                  </Box>
                </Stack>
              </form>
            </div>
            <Lottie
              id="hz-logo-animation"
              animationData={HZLogoAnimation}
              loop={false}
              style={{
                display: "inline-block",
                position: "fixed",
                filter: "contrast(0.5)",
                opacity: "0.05",
                zIndex: 0,
              }}
            />
          </Box>
        </main>
      </body>
    </>
  );
};

export default Login;
