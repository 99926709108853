import React from "react";
import { Navigation, Pagination, Parallax } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import Lottie from "lottie-react";
import SlideRightLottie from "../../../animation/Slide-Lottie2.json";
import { Link } from "react-router-dom";

function Story() {
  return (
    <section className="section-padding">
      <div className="container-fluid rest mb-100">
        <div className="col-md-12">
          <div className="text-center mb-30">
            <h1>{/* Heading */}</h1>
            <p>{/* Section description */}</p>
          </div>
        </div>
        <Swiper
          modules={[Navigation, Pagination, Parallax]}
          //navigation
          //pagination={{ clickable: true }}
          parallax
          spaceBetween={0}
          centeredSlides={true}
          loop={true}
          className="project2-swiper"
          //style={{ overflow: "visible", maxWidth: "640px" }}
        >
          <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
            <div className="slide-video-wrapper">
              <video
                className="video"
                src="/dark/assets/imgs/works/projects/web/proj-web-rsg-1.webm"
                alt="Slide 1"
                autoPlay
                loop
                muted
                width="100%"
                height="auto"
                //height={window.innerWidth > 768 ? "100%" : "auto"}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
            <div className="slide-video-wrapper">
              <video
                className="video"
                src="/dark/assets/imgs/works/projects/web/proj-web-rsg-2.webm"
                alt="Slide 2"
                autoPlay
                loop
                muted
                width="100%"
                height="auto"
                //height={window.innerWidth > 768 ? "100%" : "auto"}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
            <div className="slide-video-wrapper">
              <video
                className="video"
                src="/dark/assets/imgs/works/projects/web/proj-web-rsg-3.webm"
                alt="Slide 3"
                autoPlay
                loop
                muted
                width="100%"
                height="auto"
                //height={window.innerWidth > 768 ? "100%" : "auto"}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
            <div className="slide-video-wrapper">
              <video
                className="video"
                src="/dark/assets/imgs/works/projects/web/proj-web-rsg-1.webm"
                alt="Slide 1"
                autoPlay
                loop
                muted
                width="100%"
                height="auto"
                //height={window.innerWidth > 768 ? "100%" : "auto"}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
            <div className="slide-video-wrapper">
              <video
                className="video"
                src="/dark/assets/imgs/works/projects/web/proj-web-rsg-2.webm"
                alt="Slide 2"
                autoPlay
                loop
                muted
                width="100%"
                height="auto"
                //height={window.innerWidth > 768 ? "100%" : "auto"}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
            <div className="slide-video-wrapper">
              <video
                className="video"
                src="/dark/assets/imgs/works/projects/web/proj-web-rsg-3.webm"
                alt="Slide 3"
                autoPlay
                loop
                muted
                width="100%"
                height="auto"
                //height={window.innerWidth > 768 ? "100%" : "auto"}
              />
            </div>
          </SwiperSlide>
        </Swiper>
        <Lottie
          id="slide-lottie-img2"
          animationData={SlideRightLottie}
          loop={true}
          style={{ marginTop: "-25px" }}
        />
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="cont section-padding">
              <div className="row">
                <div className="col-md-3 sm-mb30">
                  <h6>Section Title</h6>
                </div>
                <div className="col-lg-6 col-md-9">
                  <div className="text">
                    <p>Section description</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/web/rsgui/before01.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/web/rsgui/after01.webp"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid rest mb-100">
        <div className="col-md-12">
          <div className="text-center mt-120 mb-30">
            <h1>{/* Journey Snippets */}</h1>
            <p>
              {/* Snippets the journey of the project, the process, and the final. */}
            </p>
          </div>
        </div>
        <Swiper
          modules={[Navigation, Pagination, Parallax]}
          //navigation
          //pagination={{ clickable: true }}
          parallax
          spaceBetween={0}
          centeredSlides={true}
          loop={true}
          className="project2-swiper"
          //style={{ overflow: "visible", maxWidth: "640px" }}
        >
          <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
            <div className="slide-video-wrapper">
              <video
                className="video"
                src="/dark/assets/imgs/works/projects/web/proj-web-rsg-1.webm"
                alt="Slide 1"
                autoPlay
                loop
                muted
                width="100%"
                height="auto"
                //height={window.innerWidth > 768 ? "100%" : "auto"}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
            <div className="slide-video-wrapper">
              <video
                className="video"
                src="/dark/assets/imgs/works/projects/web/proj-web-rsg-2.webm"
                alt="Slide 2"
                autoPlay
                loop
                muted
                width="100%"
                height="auto"
                //height={window.innerWidth > 768 ? "100%" : "auto"}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
            <div className="slide-video-wrapper">
              <video
                className="video"
                src="/dark/assets/imgs/works/projects/web/proj-web-rsg-3.webm"
                alt="Slide 3"
                autoPlay
                loop
                muted
                width="100%"
                height="auto"
                //height={window.innerWidth > 768 ? "100%" : "auto"}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
            <div className="slide-video-wrapper">
              <video
                className="video"
                src="/dark/assets/imgs/works/projects/web/proj-web-rsg-1.webm"
                alt="Slide 1"
                autoPlay
                loop
                muted
                width="100%"
                height="auto"
                //height={window.innerWidth > 768 ? "100%" : "auto"}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
            <div className="slide-video-wrapper">
              <video
                className="video"
                src="/dark/assets/imgs/works/projects/web/proj-web-rsg-2.webm"
                alt="Slide 2"
                autoPlay
                loop
                muted
                width="100%"
                height="auto"
                //height={window.innerWidth > 768 ? "100%" : "auto"}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
            <div className="slide-video-wrapper">
              <video
                className="video"
                src="/dark/assets/imgs/works/projects/web/proj-web-rsg-3.webm"
                alt="Slide 3"
                autoPlay
                loop
                muted
                width="100%"
                height="auto"
                //height={window.innerWidth > 768 ? "100%" : "auto"}
              />
            </div>
          </SwiperSlide>
        </Swiper>
        <Lottie
          id="slide-lottie-img2"
          animationData={SlideRightLottie}
          loop={true}
          style={{ marginTop: "-25px" }}
        />
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="cont section-padding">
              <div className="row mb-30">
                <div className="col-md-3 sm-mb30">
                  <h6>{/* Heading */}</h6>
                </div>
                <div className="col-lg-6 col-md-9">
                  <div className="text">
                    <p>{/* description */}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 sm-mb30">
                  <h6>Next on the list</h6>
                </div>
                <div className="col-lg-6 col-md-9">
                  <div className="text">
                    <p>description</p>
                    <Link
                      to="/archives/websites/rsg"
                      className="fz-28 fw-400 stroke next-archive-link"
                    >
                      Project Name
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Story;
