/* eslint-disable react-hooks/exhaustive-deps */
"use client";
import React, { useRef, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

function ImageScale() {
  const controls = useAnimation();
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          controls.start({
            y: [-150, 0],
            transition: { duration: 0.8, ease: "easeOut" },
          });
        }
      },
      {
        threshold: 0.1,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [controls]);

  return (
    <div className="in-box" style={{ width: "100%", overflow: "hidden" }}>
      <div className="img-scale">
        <div className="image" data-overlay-dark="0">
          <motion.div
            className="img"
            ref={ref}
            initial={{ y: 150 }}
            animate={controls}
          >
            <motion.img
              id="grow"
              src="/dark/assets/imgs/background/2.jpg"
              data-speed="0.2"
              data-lag="0"
              alt=""
              style={{ width: "100%", height: "auto" }}
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default ImageScale;
