import React from 'react';
import Lottie from "lottie-react";
import SlideRightLottie from "../../components/animation/Slide-Lottie.json";

const ArvhiveFilter = () => {
  return (
    <div className="filter-wrapper" id="archive-filter">
      <div className="row filter-row">
        <div className="filtering text-left">
          <div className="filter">
            <span datafilter="*" className="active filter-items">
              Show All
            </span>
            <span datafilter=".Visual-Comms" className="filter-items">
              Visual-Comms
            </span>
            <span datafilter=".Branding" className="filter-items">
              Branding
            </span>
            <span datafilter=".UIUX" className="filter-items">
              UI/UX
            </span>
            <span datafilter=".Websites" className="filter-items">
              Websites
            </span>
            <span datafilter=".Industrial" className="filter-items">
              Industrial
            </span>
            <span datafilter=".Interior" className="filter-items">
              Interior
            </span>
            <span datafilter=".Post-Production" className="filter-items">
              Post-Production
            </span>
            <span datafilter=".Motion-Graphics" className="filter-items">
              Motion-Graphics
            </span>
            <span
              datafilter=".Marketing"
              className="filter-items"
              style={{ paddingRight: "4rem" }}
            >
              Marketing
            </span>
          </div>
        </div>
      </div>
      <Lottie
        id="slide-right-lottie"
        animationData={SlideRightLottie}
        loop={true}
      />
    </div>
  );
};

export default ArvhiveFilter;