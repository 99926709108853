import styles from "./footer-copyright.module.css";

const FooterCopyright = () => {
  return (
    <div className={styles.footer}>
      <footer className={styles.footercopyright} id="Footer">
        <small
          className={styles.copyrighttext}
          id="copyright-text"
        >{`Designed & Developed by Hadi Zainal with 🔥 🎀 🎁 🧸`}</small>
      </footer>
    </div>
  );
};

export default FooterCopyright;
