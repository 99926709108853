"use client";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import Lottie from "lottie-react";
import HZLogoAnimation from "../components/animation/HZ-Logo-Lottie.json";
import NotFoundAnimation from "../components/animation/404Lottie.json"; // Renamed for clarity

export default function NotFoundPage() {
  useEffect(() => {
    document.body.style.overflow = "auto";
    console.clear();
  }, []);

  return (
    <>
      <Helmet>
        <title>404 | Page not found</title>
        <link rel="icon" href="/dark/imgs/nav/favicon.ico" />
        <link rel="shortcut icon" href="/dark/imgs/nav/favicon.ico" />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/plugins.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/style.css"
        />
      </Helmet>
      <body>
        <main className="main-bg" id="topofpage">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="100vh"
            bgcolor="#14172B"
            color="snow"
            position="relative"
            overflow="hidden"
          >
            <div className="container text-center">
              <Lottie
                id="404-lottie"
                animationData={NotFoundAnimation}
                loop={true}
                style={{
                  width: "400px",
                  zIndex: 1,
                  position: "relative",
                  transformOrigin: "center",
                  margin: "auto",
                }}
              />
              <p className="fz-4 py-2 px-2">
                The page you are looking for might have been removed, had its
                name changed or is temporarily unavailable.
              </p>
            </div>

            <Lottie
              id="hz-logo-animation"
              animationData={HZLogoAnimation}
              loop={false}
              style={{
                position: "absolute",
                width: "500px",
                filter: "contrast(0.5)",
                opacity: "0.05",
                zIndex: 0,
                margin: "auto",
              }}
            />
          </Box>
        </main>
      </body>
    </>
  );
}
