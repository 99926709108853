'use client';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import brandData from '../../data/Landing/branddata.json';

function Brands() {
  const swiperOptions = {
    speed: 600,
    loop: true,
    controls: true,
    breakpoints: {
      390: {
        loop: true,
        slidesPerView: 3,
        spaceBetween: 4,
        centeredSlides: false,
      },
      440: {
        loop: true,
        slidesPerView: 3,
        spaceBetween: 10,
        centeredSlides: false,
      },
      640: {
        loop: true,
        slidesPerView: 3,
        spaceBetween: 20,
        centeredSlides: false,
      },
      600: {
        loop: true,
        slidesPerView: 3,
        spaceBetween: 30,
        centeredSlides: false,
      },
      1200: {
        loop: true,
        slidesPerView: 5,
        spaceBetween: 40,
        centeredSlides: false,
      },
    },
  };

  return (
    <div className="clients-carso2 marquee">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="swiper5">
              <Swiper
                id="content-carousel-container-unq-clients"
                className="swiper-container"
                data-swiper="container"
                {...swiperOptions}
              >
                {brandData.map((brand, index) => (
                  <SwiperSlide key={index} className="swiper-slide" style={{ width: "fit-content" }}>
                    <div className="item">
                      <div className="img icon-img-100">
                        <p className="text-center" style={{ fontSize: "smaller" }}>
                          <img
                            src={brand.src}
                            alt={brand.alt}
                            style={{ filter: "invert(1)" }}
                          />
                          {brand.text}
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Brands;