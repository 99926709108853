import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Challenge() {
  return (
    <section className="section-padding bord-thin-bottom">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <h1 className="mb-50">
              The
              <br /> Process
            </h1>
          </div>
          <div className="col-lg-7">
            <div className="text">
              <h6 className="line-height-40">
                A few words about the project.
              </h6>
            </div>
            <div className="text mt-30">
              <p>
                Project description.
              </p>
            </div>
            <div className="accordion bord mt-50">
              <Accordion
                defaultExpanded
                sx={{
                  backgroundColor: "#040404",
                  color: "white",
                  borderBottom: "1px solid #0d122e",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "yellowgreen" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">Stage 1</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Stage 1 description.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={{
                  backgroundColor: "#040404",
                  color: "white",
                  borderBottom: "1px solid #0d122e",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "yellowgreen" }} />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography variant="h6">Stage 2</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Stage 2 description.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={{
                  backgroundColor: "#040404",
                  color: "white",
                  borderBottom: "1px solid #0d122e",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "yellowgreen" }} />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography variant="h6">Stage 3</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                   Stage 3 description.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={{
                  backgroundColor: "#040404",
                  color: "white",
                  borderBottom: "1px solid #0d122e",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "yellowgreen" }} />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography variant="h6">
                    Stage 4
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Stage 4 description.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Challenge;
