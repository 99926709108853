import React from "react";
import { Link } from "react-router-dom";

const TopLogo = () => {
  return (
    <div
      className="logo-container"
      height={50}
      width={50}
      style={{
        position: "fixed",
        display: "block",
        height: "50px",
        width: "50px",
        top: "0",
        left: "0",
        zIndex: "99",
      }}
    >
      <Link to="/" className="logo top-logo-button">
        <div className="top-logo" height={40} width={40}>
          <svg
            id="top-logo-svg"
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 80 80"
          >
            <path
              d="M1.52588e-05 0L4.88086e-06 59.3548L10.2128 59.3548L10.2128 32.6541L37.636 32.6541L37.636 8.3805L66.0257 8.3805L14.3946 59.3548L26.4306 59.3548L80 6.46685L80 1.39876e-05L27.423 4.79479e-06L27.423 24.2516L10.2128 24.2516L10.2128 1.78567e-06L1.52588e-05 0Z"
              fill="#F0F8FF"
            />
            <path
              d="M33.0969 59.3548L33.0969 59.1682L41.4187 50.9525L80 50.9525L80 59.3548L33.0969 59.3548Z"
              fill="#F0F8FF"
            />
          </svg>
        </div>
      </Link>
    </div>
  );
};

export default TopLogo;
