/* eslint-disable jsx-a11y/img-redundant-alt */
import { useState, useEffect } from "react";

const MasonryGrid = () => {
  const [loaded, setLoaded] = useState(false);

  // Simulate image loading time
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 2000); // Adjust the delay as needed
    return () => clearTimeout(timer);
  }, []);

  const images = [];
  for (let i = 11; i <= 30; i++) {
    images.push(
      `/dark/assets/imgs/works/projects/interior/interior-fti/A0${i}.webp`
    );
  }

  return (
    <div className="container mb-120">
      <div className="row">
        <p className="text-left mb-30">
          Winning the pitch and leading the project from concept to successful
          tender, I collaborated closely with FTI Consulting to ensure the
          design aligned with their corporate vision while creating an
          inspiring, functional workplace. Through thoughtful design and
          meticulous attention to detail, the result was a harmonious blend of
          aesthetics and purpose, providing a symbolically rich, future-forward
          environment for FTI’s Singapore office.
        </p>
        {images.map((imgSrc, index) => (
          <div key={index} className="col-md-4 col-sm-6 mb-4">
            {!loaded ? (
              <div
                className="skeleton rounded"
                style={{ width: "100%", height: "300px" }}
              ></div>
            ) : (
              <img
                src={imgSrc}
                alt={`Interior FTI Image ${index}`}
                className="img-fluid rounded"
                style={{ width: "100%", height: "auto" }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MasonryGrid;
