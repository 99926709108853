import React, { useState, useEffect } from "react";
import { LinearProgress, Box, Typography } from "@mui/material";
import { useInView } from "react-intersection-observer";

const skills = [
  { name: "Brand Identity", value: 95 },
  { name: "Web Design and Development", value: 95 },
  { name: "Marketing Analytics and Campaign Management", value: 95 },
  { name: "Post-Production and Motion-Graphics", value: 95 },
];

function Skills() {
  return (
    <div className="skills section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="skil-progs md-mb80">
              <Typography variant="h3">
                Professional <span className="fw-300">Skills</span>
              </Typography>
              <Typography variant="body2" className="fz-13 mt-15">
                Current focus is on the design and development of digital
                products and services.
              </Typography>

              {skills.map((skill, index) => (
                <SkillItem key={index} skill={skill} />
              ))}
            </div>
          </div>
          <div className="col-lg-5 offset-lg-1">
            <div className="resume-exp">
              <div className="box-items row mt-100">
                <div className="col-md-12 item">
                  <Typography variant="h6" className="text-center">
                    <span className="num">
                      1 <small>st</small>
                    </span>
                    <br />
                    Marketing Lead & Snr Designer
                    <br />
                    <span className="opacity-8">2020 - Present</span>
                    <br />
                    <span className="opacity-3 fz-12">Real Serious Games</span>
                  </Typography>
                </div>
                <div className="col-md-12 item">
                  <Typography variant="h6" className="text-center">
                    <span className="num">
                      2 <small>nd</small>
                    </span>
                    <br />
                    Design Director & Co-Founder
                    <br />
                    <span className="opacity-8">2015 - Present</span>
                    <br />
                    <span className="opacity-3 fz-12">Digital Hustlaz</span>
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function SkillItem({ skill }) {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (inView) {
      setProgress(0);
      const increment = skill.value / 100;
      const interval = setInterval(() => {
        setProgress((prev) => {
          if (prev < skill.value) {
            return prev + increment;
          } else {
            clearInterval(interval);
            return skill.value;
          }
        });
      }, 10);
      return () => clearInterval(interval);
    }
  }, [inView, skill.value]);

  return (
    <div className="skill-item mt-30" ref={ref}>
      <Typography variant="h6" className="fz-16 mb-10">
        {skill.name}
      </Typography>
      <Box className="skill-progress" display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              height: "10px",
              backgroundColor: "#00000021",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "yellowgreen",
              },
            }}
          />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="white">{`${Math.round(
            progress
          )}%`}</Typography>
        </Box>
      </Box>
    </div>
  );
}

export default Skills;
