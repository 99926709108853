import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Challenge() {
  return (
    <section className="section-padding bord-thin-bottom">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <h1 className="mb-50">
              The <br /> Design
              <br /> Process
            </h1>
          </div>
          <div className="col-lg-7">
            <div className="text">
              <h6 className="line-height-40">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </h6>
            </div>
            <div className="text mt-30">
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </div>
            <div className="accordion bord mt-50">
              <Accordion
                defaultExpanded
                sx={{
                  backgroundColor: "#040404",
                  color: "white",
                  borderBottom: "1px solid #0d122e",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "yellowgreen" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">Title Header</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </Typography>
                  <div className="img mt-30">
                    <img
                      src="/dark/assets/imgs/works/projects/indus/anza/12.webp"
                      alt="Slide 1"
                    />
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={{
                  backgroundColor: "#040404",
                  color: "white",
                  borderBottom: "1px solid #0d122e",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "yellowgreen" }} />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography variant="h6">Stage</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  backgroundColor: "#040404",
                  color: "white",
                  borderBottom: "1px solid #0d122e",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "yellowgreen" }} />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography variant="h6">Stage</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </Typography>
                </AccordionDetails>
              </Accordion>


            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Challenge;
