import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Challenge() {
  return (
    <section className="section-padding bord-thin-bottom">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <h1 className="mb-50">
              The <br /> Branding
              <br /> Process
            </h1>
          </div>
          <div className="col-lg-7">
            <div className="text">
              <h6 className="line-height-40">
                The new brand was to set brand dominance in the market and
                create a strong brand presence with their pioneering work in the
                field of virtual reality. Utilising VR gaming technology to
                create educational and training experiences for a range of
                industries.
              </h6>
            </div>
            <div className="text mt-30">
              <p>
                The branding process is a structured journey designed to create
                a strong and cohesive brand identity that resonates with the
                target audience and stands out in the market. This process is
                typically divided into four main stages: Branding Workshop,
                Brand Archetypes, Market Positioning, and Refinements leading to
                the Final Brand Guide Documentation.
              </p>
            </div>
            <div className="accordion bord mt-50">
              <Accordion
                defaultExpanded
                sx={{
                  backgroundColor: "#040404",
                  color: "white",
                  borderBottom: "1px solid #0d122e",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "yellowgreen" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">Branding Workshop</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The branding process begins with a comprehensive workshop,
                    where key stakeholders come together to define the essence
                    of the brand. During this phase, we explore your company's
                    mission, vision, and core values. We also analyze the
                    competitive landscape and identify unique selling points
                    that differentiate your brand. This workshop serves as the
                    foundation for understanding the brand's purpose, goals, and
                    target audience, and sets the stage for developing a
                    compelling brand strategy.
                  </Typography>
                  <div className="img mt-30">
                    <img
                      src="/dark/assets/imgs/works/projects/brand-rsg/01.png"
                      alt="Slide 1"
                    />
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={{
                  backgroundColor: "#040404",
                  color: "white",
                  borderBottom: "1px solid #0d122e",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "yellowgreen" }} />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography variant="h6">Brand Archetypes</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Brand archetypes are essential tools in brand psychology,
                    helping to shape the brand's personality and emotional
                    connection with the audience. Based on insights from the
                    branding workshop, we identify which archetype best aligns
                    with your brand's identity. Whether your brand is a Hero, an
                    Explorer, or a Sage, understanding its archetype helps in
                    crafting messaging, visuals, and experiences that resonate
                    with your audience on a deeper psychological level. This
                    stage is crucial for defining the brand’s tone of voice,
                    style, and overall communication strategy.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={{
                  backgroundColor: "#040404",
                  color: "white",
                  borderBottom: "1px solid #0d122e",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "yellowgreen" }} />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography variant="h6">Market Positioning</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Positioning your brand in the market is a strategic exercise
                    that involves analyzing your competitors, understanding your
                    audience's needs, and identifying the most compelling way to
                    present your brand. This phase integrates the insights from
                    the brand archetypes and the branding workshop to create a
                    positioning statement that clearly articulates your brand’s
                    value proposition. By defining where your brand sits in the
                    marketplace, we ensure it stands out and appeals to the
                    right audience.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={{
                  backgroundColor: "#040404",
                  color: "white",
                  borderBottom: "1px solid #0d122e",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "yellowgreen" }} />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography variant="h6">
                    Refinements and Final Brand Guide Documentation
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Once the core elements of the brand have been established,
                    we move into the refinement phase. Here, we fine-tune the
                    visual identity, messaging, and other brand assets to ensure
                    consistency and alignment with the brand strategy. This
                    phase often involves iterations and feedback loops to ensure
                    every aspect of the brand is polished and cohesive. The
                    process culminates in the creation of a comprehensive Brand
                    Guide document, which serves as a blueprint for maintaining
                    brand consistency across all platforms and communications.
                    This guide includes guidelines on logo usage, color
                    palettes, typography, imagery, and tone of voice, ensuring
                    that every touchpoint reflects the brand’s identity
                    accurately.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Challenge;
